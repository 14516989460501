import React from 'react';
import ReactDOM from 'react-dom';
import { MsalAuthProvider } from '@innit/msal-browser-react';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { enableAppInsights } from './appInsights';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { msalConfig } from './config/msalConfig';
import { appSettings } from './config/appSettings';

ReactDOM.render(
  <React.StrictMode>
    <MsalAuthProvider
      msalConfig={msalConfig}
      loginScopes={appSettings.auth.loginScopes}
      apiScopes={appSettings.auth.apiScopes}
    >
      <Router>
        <App />
      </Router>
    </MsalAuthProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
enableAppInsights();
