import { makeStyles } from '@material-ui/core/styles';
import { dodgerBlue, gallery } from 'styles/jsColors';

const jsStyles = makeStyles({
  addtorowcontainer: {
    display: 'flex',
  },
  addtorowbutton: {
    fontSize: '12px',
    width: '150px',
    color: dodgerBlue,
  },
  input: {
    width: '40px',
    backgroundColor: gallery,
    border: 'none',
    outline: 'none',
    textAlign: 'center',
  },
});

export default jsStyles;
