import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const MainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#274457',
    },
    secondary: {
      main: '#ffffff',
    },
  },
});

export default MainTheme;
