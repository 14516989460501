export interface IPlannedTableCountry {
  name: string;
  id: number;
}

export interface IPlannedTableFacility {
  name: string;
  id: string;
}

export interface IPlannedForecastValue {
  year: string;
  week: string;
  budgetForecast: number;
  requiredForecast: number;
  plannedForecast: number;
  delivered: number;
  budgetDeviation: number;
  requriedDeviation: number;
  plannedDeviation: number;
  plannedRequiredDeviation: number;
}

export interface IPlannedForecastTable {
  facility: IPlannedTableFacility;
  forecasts: IPlannedForecastValue[];
}

export interface IPlanningTableGetModel {
  facilityId: string;
  hasMiscRows: boolean;
  weeks: string[];
  budgetRows: IPlanningTableRow[];
  requiredRows: IPlanningTableRow[];
  plannedRows: IPlanningTableRow[];
  deliveredRows: IPlanningTableRow[];
}

export enum Dimension {
  Budget = 'LOG_BUDGET',
  Required = 'LOG_REQUIRED',
  Planned = 'LOG_PLANNED',
  Delivered = 'LOG_DELIV',
}

export interface IPlanningTableRow {
  dimension: Dimension;
  senderCountryId: string;
  senderCountry: string;
  transportationId: string;
  transportation: string;
  fractionId: string;
  fraction: string;
  misc: string;
  cells: IPlanningTableCell[];
}
export interface IPlanningTableCell {
  year: string;
  week: string;
  value: number;
}

export interface IPlanningTablePutModel {
  dimension: string;
  senderCountryId: string;
  transportationId: string;
  fractionId: string;
  misc: string;
  year: string;
  week: string;
  value: number;
}

export interface ISelectedCell {
  dimension: Dimension;
  senderCountryId: string;
  transportationId: string;
  fractionId: string;
  misc: string;
  year: string;
  week: string;
  value: number;
}
