import {
  DistributedTracingModes,
  Snippet,
} from '@microsoft/applicationinsights-web';
import { appSettings } from './appSettings';

export const appInsightsConfig: Snippet = {
  config: {
    instrumentationKey: appSettings.appInsights.instrumentationKey,
    enableAutoRouteTracking: true,
    appId: 'geminor-webapp',
    distributedTracingMode: DistributedTracingModes.W3C,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
  },
};

export default appInsightsConfig;
