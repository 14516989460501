export type AppSettings = {
  environment: string;
  isDevelopment: boolean;
  isStaging: boolean;
  isProduction: boolean;
  auth: {
    clientId: string;
    authority: string;
    redirectUrl: string;
    postLogoutUrl: string;
    loginScopes: string[];
    apiScopes: string[];
  };
  appInsights: {
    instrumentationKey: string;
    logLevel: 'information' | 'warning' | 'error';
  };
  api: {
    host: string;
  };
};

export const appSettings: AppSettings = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  isDevelopment: process.env.REACT_APP_ENVIRONMENT === 'development',
  isStaging: process.env.REACT_APP_ENVIRONMENT === 'staging',
  isProduction: process.env.REACT_APP_ENVIRONMENT === 'production',
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENTID,
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    redirectUrl: process.env.REACT_APP_AUTH_REDIRECT_URL,
    postLogoutUrl: process.env.REACT_APP_AUTH_POST_LOGOUT_URL,
    loginScopes: process.env.REACT_APP_AUTH_LOGIN_SCOPES?.split(','),
    apiScopes: process.env.REACT_APP_AUTH_API_SCOPES?.split(','),
  },
  appInsights: {
    instrumentationKey:
      process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATIONKEY,
    logLevel: process.env.REACT_APP_APPLICATION_LOG_LEVEL,
  },
  api: {
    host: process.env.REACT_APP_BACKEND_API_HOST,
  },
};

export default appSettings;
