/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import {
  IPlanningTableGetModel,
  IPlanningTableRow,
  IPlanningTableCell,
  ISelectedCell,
  Dimension,
} from 'interfaces/PlannedTable';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Modal,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import { gray, white } from 'styles/jsColors';
import { IComment } from 'interfaces/Comment';
import CommentInput from 'components/CommentInput/CommentInput';
import NumberFormat from 'react-number-format';
import CloseIcon from '@material-ui/icons/Close';
import AddToRow from '../AddToRow/AddToRow';
import TableInput from '../TableInput/TableInput';
import jsStyles from './jsStyles';

import styles from './styles.module.scss';

interface IFilter {
  id: string;
  name: string;
}

type ExpandedPlannedTableProps = {
  table: IPlanningTableGetModel;
  setTableUpdated: (bool: boolean) => void;
  comments: IComment[];
  onSave: (table: IPlanningTableGetModel) => Promise<void>;
  onPostComment: (comment?: IComment) => void;
  onUpdateComment: (comment?: IComment) => void;
  onDeleteComment: (comment?: IComment) => void;
  checkedCountries: IFilter[];
  checkedFractions: IFilter[];
  checkedTransports: string[];
  showBudget: boolean;
  showPlanned: boolean;
  showRequired: boolean;
  showDelivered: boolean;
};

const NEW_COMMENT = 'new_comment';
const UPDATE_COMMENT = 'update_comment';
const ERROR_COMMENT = 'error_comment';

const ExpandedPlannedTable = ({
  table,
  setTableUpdated,
  onSave,
  comments = [],
  onPostComment,
  onUpdateComment,
  onDeleteComment,
  checkedCountries,
  checkedFractions,
  checkedTransports,
  showBudget,
  showPlanned,
  showRequired,
  showDelivered,
}: ExpandedPlannedTableProps): JSX.Element => {
  const classes = jsStyles();

  const [data, setData] = useState<IPlanningTableGetModel>(table);
  const [newData, setNewData] = useState<IPlanningTableGetModel>(
    JSON.parse(JSON.stringify(table)),
  );
  const [selectedElemets, setSelectedElemets] = useState<ISelectedCell[]>([]);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

  const [commentMode, setCommentMode] = useState<string>(NEW_COMMENT);
  const [selectedComment, setSelectedComment] = useState<IComment>();
  const [commentsModalOpen, setCommentsModalOpen] = useState<boolean>(false);

  const [selectedSum, setSelectedSum] = useState<number>(0);

  const tableRef = React.useRef<HTMLDivElement>(null);
  const inputBoxRef = React.useRef<HTMLElement>(null);

  useEffect(() => {
    const sum = selectedElemets.reduce((a, b) => a + b.value, 0);
    setSelectedSum(sum);
  }, [selectedElemets]);

  useEffect(() => {
    const checkCommentInSelected = () => {
      if (selectedElemets?.length > 0) {
        if (comments?.length > 0) {
          const search = {
            dimension: selectedElemets[0].dimension,
            fractionId: selectedElemets[0].fractionId,
            senderId: selectedElemets[0].senderCountryId,
            transportationId: selectedElemets[0].transportationId,
            weekFrom: selectedElemets[0].week,
            weekTo: selectedElemets[selectedElemets.length - 1].week,
            misc: selectedElemets[0].misc,
          };
          let commentsCounter = 0;

          selectedElemets.forEach((element) => {
            if (
              comments.some(
                (comment) =>
                  Number(comment.weekFrom) <= Number(element.week) &&
                  Number(comment.weekTo) >= Number(element.week) &&
                  comment.fractionId === element.fractionId &&
                  comment.senderId === element.senderCountryId &&
                  comment.dimension === element.dimension &&
                  comment.transportId === element.transportationId &&
                  comment.misc === element.misc,
              )
            ) {
              commentsCounter += 1;
            }
          });

          if (commentsCounter === 0) {
            setCommentMode(NEW_COMMENT);
            const tempComment = {
              dimension: selectedElemets[0].dimension,
              transportId: selectedElemets[0].transportationId,
              senderId: selectedElemets[0].senderCountryId,
              fractionId: selectedElemets[0].fractionId,
              color: '#FC9079',
              message: '',
              weekFrom: selectedElemets[0].week,
              weekTo: selectedElemets[selectedElemets.length - 1].week,
              yearFrom: selectedElemets[0].year,
              yearTo: selectedElemets[selectedElemets.length - 1].year,
              misc: selectedElemets[0].misc || '',
            };
            setSelectedComment(tempComment);
          } else if (commentsCounter === selectedElemets.length) {
            const c = comments.filter(
              (comment) =>
                comment.transportId === search.transportationId &&
                comment.dimension === search.dimension &&
                comment.senderId === search.senderId &&
                comment.misc === search.misc &&
                Number(comment.weekFrom) <= Number(search.weekFrom) &&
                Number(comment.weekTo) >= Number(search.weekTo) &&
                comment.fractionId === search.fractionId,
            );
            if (c.length === 1) {
              setSelectedComment(c[0]);
              setCommentMode(UPDATE_COMMENT);
            } else {
              setCommentMode(ERROR_COMMENT);
            }
          } else {
            setCommentMode(ERROR_COMMENT);
          }
        } else {
          setCommentMode(NEW_COMMENT);
          const tempComment = {
            dimension: selectedElemets[0].dimension,
            transportId: selectedElemets[0].transportationId,
            senderId: selectedElemets[0].senderCountryId,
            fractionId: selectedElemets[0].fractionId,
            color: '#FC9079',
            message: '',
            weekFrom: selectedElemets[0].week,
            weekTo: selectedElemets[selectedElemets.length - 1].week,
            yearFrom: selectedElemets[0].year,
            yearTo: selectedElemets[selectedElemets.length - 1].year,
            misc: selectedElemets[0].misc || '',
          };
          setSelectedComment(tempComment);
        }
      }
    };
    checkCommentInSelected();
  }, [selectedElemets, comments]);

  const updateSelectElements = (
    row: IPlanningTableRow,
    cell: IPlanningTableCell,
  ) => {
    const element = {
      dimension: row.dimension,
      senderCountryId: String(row.senderCountryId),
      transportationId: String(row.transportationId),
      fractionId: row.fractionId,
      misc: row.misc,
      year: cell.year,
      week: cell.week,
      value: cell.value,
    };

    if (!selectedElemets.some((el) => el.week === element.week)) {
      setSelectedElemets(
        [...selectedElemets, element].sort(
          (a, b) => Number(a.week) - Number(b.week),
        ),
      );
    }
  };

  const addToSelected = (row: IPlanningTableRow, cell: IPlanningTableCell) => {
    if (isMouseDown) {
      if (
        (selectedElemets?.length > 0 &&
          selectedElemets[0].dimension === row.dimension &&
          selectedElemets[0].senderCountryId === String(row.senderCountryId) &&
          selectedElemets[0].transportationId ===
            String(row.transportationId) &&
          selectedElemets[0].misc === String(row.misc) &&
          selectedElemets[0].fractionId === row.fractionId) ||
        selectedElemets?.length === 0
      ) {
        updateSelectElements(row, cell);
      }
    }
  };

  const getBackgroundColor = (
    row: IPlanningTableRow,
    cell: IPlanningTableCell,
    defaultColor: string = white,
  ) => {
    let backgroundColor = defaultColor;
    if (comments?.length > 0) {
      const commentExsists = comments.find(
        (comment) =>
          comment.dimension === row.dimension &&
          comment.senderId === String(row.senderCountryId) &&
          comment.transportId === String(row.transportationId) &&
          comment.fractionId === row.fractionId &&
          comment.misc === row.misc &&
          Number(comment.weekFrom) <= Number(cell.week) &&
          Number(comment.weekTo) >= Number(cell.week),
      );
      backgroundColor = commentExsists?.color || defaultColor;
    }

    return backgroundColor;
  };

  /**
   * @param row the row
   * @param cell the cell
   * @returns comment for a specific cell.
   */
  const getComment = (row: IPlanningTableRow, cell: IPlanningTableCell) => {
    let commentMessage = '';
    if (comments?.length > 0) {
      const commentExsists = comments.find(
        (comment) =>
          comment.dimension === row.dimension &&
          comment.senderId === String(row.senderCountryId) &&
          comment.transportId === String(row.transportationId) &&
          comment.fractionId === row.fractionId &&
          comment.misc === row.misc &&
          Number(comment.weekFrom) <= Number(cell.week) &&
          Number(comment.weekTo) >= Number(cell.week),
      );
      commentMessage = commentExsists?.message || '';
    }

    return commentMessage;
  };

  const updateValue = (
    dimension: Dimension,
    sendingCountryId: string,
    transportId: string,
    fractionId: string,
    cellIndex: number,
    newValue: number,
    misc = '',
  ) => {
    if (!Number.isNaN(newValue)) {
      const tempData: IPlanningTableGetModel = data;
      switch (dimension) {
        case Dimension.Required:
          tempData.requiredRows.map((row) => {
            if (
              row.senderCountryId === sendingCountryId &&
              row.transportationId === transportId &&
              row.fractionId === fractionId &&
              row.misc === misc
            ) {
              row.cells[cellIndex].value = newValue;
            }
            return row;
          });
          break;
        case Dimension.Planned:
          tempData.plannedRows.map((row) => {
            if (
              row.senderCountryId === sendingCountryId &&
              row.transportationId === transportId &&
              row.fractionId === fractionId &&
              row.misc === misc
            ) {
              row.cells[cellIndex].value = newValue;
            }
            return row;
          });
          break;
        case Dimension.Delivered:
          tempData.deliveredRows.map((row) => {
            if (
              row.senderCountryId === sendingCountryId &&
              row.transportationId === transportId &&
              row.fractionId === fractionId &&
              row.misc === misc
            ) {
              row.cells[cellIndex].value = newValue;
            }
            return row;
          });
          break;
      }

      setTableUpdated(true);
      setData({ ...tempData });
    }
  };

  const updateSelectedCommentMessage = (newMessage: string) => {
    const tempComment = { ...selectedComment, message: newMessage };
    setSelectedComment(tempComment);
  };

  const updateSelectedCommentColor = (newColor: string) => {
    const tempComment = { ...selectedComment, color: newColor };
    setSelectedComment(tempComment);
  };

  /**
   * @param tableData all rows for a given dimension
   * @param weekIndex the week index to sum. Earliest week is [0]
   * @returns sum of a week for a given dimension
   */
  const totalColumn = (tableData: IPlanningTableRow[], weekIndex: number) => {
    return tableData.reduce(
      (sum, item) => sum + item.cells[weekIndex].value ?? 0,
      0,
    );
  };

  /**
   * @param tableData the row to sum.
   * @returns sum of all weeks for a row.
   */
  const totalRow = (tableData: IPlanningTableRow) => {
    return tableData.cells.reduce((sum, item) => sum + item.value, 0);
  };

  /**
   * @param tableData The dimension rows.
   * @returns sum of all dimension cells.
   */
  const dimensionTotal = (tableData: IPlanningTableRow[]) => {
    return tableData.reduce(
      (total, row) =>
        total + row.cells.reduce((sum, cell) => sum + cell.value, 0),
      0,
    );
  };

  const handleOnAddToAllCells = (row: IPlanningTableRow, value: number) => {
    const tempData = data;
    switch (row.dimension) {
      case Dimension.Required:
        tempData.requiredRows.map((tempRow) => {
          if (
            tempRow.senderCountryId === row.senderCountryId &&
            tempRow.transportationId === row.transportationId &&
            tempRow.fractionId === row.fractionId &&
            tempRow.misc === row.misc
          ) {
            tempRow.cells.map((cell) => (cell.value = value));
          }
          return tempRow;
        });
        break;
      case Dimension.Planned:
        tempData.plannedRows.map((tempRow) => {
          if (
            tempRow.senderCountryId === row.senderCountryId &&
            tempRow.transportationId === row.transportationId &&
            tempRow.fractionId === row.fractionId &&
            tempRow.misc === row.misc
          ) {
            tempRow.cells.map((cell) => (cell.value = value));
          }
          return tempRow;
        });
        break;
      case Dimension.Delivered:
        tempData.deliveredRows.map((tempRow) => {
          if (
            tempRow.senderCountryId === row.senderCountryId &&
            tempRow.transportationId === row.transportationId &&
            tempRow.fractionId === row.fractionId &&
            tempRow.misc === row.misc
          ) {
            tempRow.cells.map((cell) => (cell.value = value));
          }
          return tempRow;
        });
        break;
    }

    setTableUpdated(true);
    setData({ ...tempData });
  };

  type IFilterByCountryProp =
    | 'budgetRows'
    | 'requiredRows'
    | 'plannedRows'
    | 'deliveredRows';

  const handleFilterByCountry = (prop: IFilterByCountryProp) => {
    const tmpDataFilter = data[prop].filter(
      (deliveredRow) =>
        checkedCountries?.some(
          (country) =>
            country.id.toString() === deliveredRow.senderCountryId.toString(),
        ) || checkedCountries?.length === 0,
    );

    const filteredDataByFraction = tmpDataFilter.filter(
      (transport) =>
        checkedFractions?.some(
          (fraction) => fraction.name.toString() === transport.fraction,
        ) || checkedFractions?.length === 0,
    );

    const filteredData = filteredDataByFraction.filter(
      (transport) =>
        checkedTransports.some(
          (t) => t.toString() === transport.transportation,
        ) || checkedTransports.length === 0,
    );

    return { [prop]: filteredData };
  };

  useEffect(() => {
    const filterByCountry = () => {
      let filteredDataByCountriesDelivered;
      let filteredDataByCountriesRequired;
      let filteredDataByCountriesPlanned;
      let filteredDataByCountriesBudget;

      if (showDelivered) {
        filteredDataByCountriesDelivered = handleFilterByCountry(
          'deliveredRows',
        );
      }

      if (showRequired) {
        filteredDataByCountriesRequired = handleFilterByCountry('requiredRows');
      }

      if (showPlanned) {
        filteredDataByCountriesPlanned = handleFilterByCountry('plannedRows');
      }

      if (showBudget) {
        filteredDataByCountriesBudget = handleFilterByCountry('budgetRows');
      }

      const filteredDataByCountries = {
        ...data,
        ...filteredDataByCountriesDelivered,
        ...filteredDataByCountriesRequired,
        ...filteredDataByCountriesPlanned,
        ...filteredDataByCountriesBudget,
      };

      setNewData(filteredDataByCountries);
    };
    filterByCountry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedCountries, checkedFractions, checkedTransports, data]);

  const scrollCell = () => {
    const tableElement = document.getElementById('ExpandedTableWrapper');
    if (tableElement !== null) {
      tableElement.scrollLeft += 112;
    }
  };

  const enterDragMode = () => {
    setSelectedElemets([]);
    setIsMouseDown(true);
  };

  const showMisc = newData.hasMiscRows;
  const HandleMisc = ({ misc }: { misc: string }): JSX.Element => {
    return (
      <>
        {showMisc && (
          <TableCell
            className={cn(classes.readonlycell, classes.stickyfractioncell)}
            align='left'
          >
            {misc}
          </TableCell>
        )}
      </>
    );
  };

  return (
    <>
      <div
        onMouseDown={enterDragMode}
        onMouseUp={() => setIsMouseDown(false)}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            setSelectedElemets([]);
            scrollCell();
          }
        }}
        className={styles.expandedPlannedTableWrapper}
        ref={tableRef}
        id='ExpandedTableWrapper'
      >
        <Table className={classes.table} aria-label='Expnded planned table'>
          <TableHead className={classes.tablehead}>
            <TableRow>
              <TableCell
                align='left'
                className={cn(classes.headercell, classes.stickycountrycell)}
              >
                Country
              </TableCell>
              <TableCell
                className={cn(
                  classes.headercell,
                  classes.stickytransportationcell,
                )}
                align='left'
              >
                Transportation
              </TableCell>
              <TableCell
                className={cn(classes.headercell, classes.stickyfractioncell)}
                align='left'
              >
                Fraction
              </TableCell>

              {showMisc && (
                <TableCell
                  className={cn(classes.headercell, classes.stickyfractioncell)}
                  align='left'
                >
                  Attribute
                </TableCell>
              )}
              {newData.weeks.map((week) => (
                <TableCell
                  className={cn(classes.cell, classes.weekheader)}
                  key={`header-week ${week}`}
                  align='center'
                >{`Week ${Number(week)}`}</TableCell>
              ))}
              <TableCell
                className={cn(
                  classes.cell,
                  classes.headercell,
                  classes.stickytotalcell,
                )}
                key='Total'
                align='center'
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tablebody}>
            {showBudget && (
              <TableRow key='budget total Row'>
                <TableCell
                  align='left'
                  className={cn(
                    classes.readonlycell,
                    classes.stickycountrycell,
                    classes.bold,
                  )}
                  colSpan={showMisc ? 3 : 2}
                >
                  Delivery plan (Read-only)
                </TableCell>
                <TableCell className={cn(classes.readonlycell, classes.bold)} />
                {newData.budgetRows[0]?.cells?.length > 0 &&
                  newData.budgetRows[0].cells.map((cell, cellIndex) => (
                    <TableCell
                      align='center'
                      key={`budget-total-${cell.year}-${cell.week}`}
                      className={cn(classes.readonlycell, classes.bold)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {cellIndex === 0 && (
                          <div
                            style={{
                              width: 25,
                            }}
                          ></div>
                        )}
                        <NumberFormat
                          value={totalColumn(newData.budgetRows, cellIndex)}
                          readOnly
                          thousandSeparator={' '}
                          type='text'
                          displayType='text'
                          isNumericString
                        />
                      </div>
                    </TableCell>
                  ))}
                <TableCell
                  align='center'
                  key='total total'
                  className={cn(
                    classes.readonlycell,
                    classes.bold,
                    classes.stickytotalcell,
                  )}
                >
                  <NumberFormat
                    value={dimensionTotal(newData.budgetRows)}
                    readOnly
                    thousandSeparator={' '}
                    type='text'
                    displayType='text'
                    isNumericString
                  />
                </TableCell>
              </TableRow>
            )}
            {showBudget &&
              newData.budgetRows.map((row) => {
                return (
                  <TableRow
                    key={`budget-row-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}`}
                  >
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickycountrycell,
                      )}
                      align='left'
                    >
                      {row.senderCountryId}
                    </TableCell>
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickytransportationcell,
                      )}
                      align='left'
                    >
                      {row.transportation}
                    </TableCell>
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickyfractioncell,
                      )}
                      align='left'
                    >
                      {row.fraction}
                    </TableCell>
                    <HandleMisc misc={row?.misc} />

                    {row.cells.map((cell, cellIndex) => (
                      <Tooltip
                        key={`budget-cell-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}-${cell.year}-${cell.week}`}
                        classes={{ tooltip: classes.tooltip }}
                        title={getComment(row, cell)}
                      >
                        <TableCell
                          align='center'
                          className={classes.readonlycell}
                          style={{
                            backgroundColor: getBackgroundColor(
                              row,
                              cell,
                              gray,
                            ),
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {cellIndex === 0 && (
                              <div
                                style={{
                                  width: 25,
                                }}
                              ></div>
                            )}

                            <NumberFormat
                              value={cell.value}
                              readOnly
                              thousandSeparator={' '}
                              type='text'
                              displayType='text'
                              isNumericString
                            />
                          </div>
                        </TableCell>
                      </Tooltip>
                    ))}
                    <TableCell
                      align='center'
                      key='total budget'
                      className={cn(
                        classes.readonlycell,
                        classes.bold,
                        classes.stickytotalcell,
                      )}
                    >
                      <NumberFormat
                        value={totalRow(row)}
                        readOnly
                        thousandSeparator={' '}
                        type='text'
                        displayType='text'
                        isNumericString
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {showRequired && (
              <TableRow key='required Row'>
                <TableCell
                  className={cn(
                    classes.readonlycell,
                    classes.stickycountrycell,
                    classes.bold,
                  )}
                  align='left'
                  colSpan={showMisc ? 3 : 2}
                >
                  Requried
                </TableCell>
                <TableCell className={cn(classes.readonlycell, classes.bold)} />
                {newData.requiredRows[0]?.cells?.length > 0 &&
                  newData.requiredRows[0]?.cells?.map((cell, cellIndex) => (
                    <TableCell
                      align='center'
                      key={`required-total-${cell.year}-${cell.week}`}
                      className={cn(classes.readonlycell, classes.bold)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {cellIndex === 0 && (
                          <div
                            style={{
                              width: 25,
                            }}
                          ></div>
                        )}
                        <NumberFormat
                          value={totalColumn(newData.requiredRows, cellIndex)}
                          readOnly
                          thousandSeparator={' '}
                          type='text'
                          displayType='text'
                          isNumericString
                        />
                      </div>
                    </TableCell>
                  ))}
                <TableCell
                  align='center'
                  key='total total'
                  className={cn(
                    classes.readonlycell,
                    classes.bold,
                    classes.stickytotalcell,
                  )}
                >
                  <NumberFormat
                    value={dimensionTotal(newData.requiredRows)}
                    readOnly
                    thousandSeparator={' '}
                    type='text'
                    displayType='text'
                    isNumericString
                  />
                </TableCell>
              </TableRow>
            )}
            {showRequired &&
              newData.requiredRows.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={`required-row-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}`}
                  >
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickycountrycell,
                      )}
                      align='left'
                    >
                      {row.senderCountryId}
                    </TableCell>
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickytransportationcell,
                      )}
                      align='left'
                    >
                      {row.transportation}
                    </TableCell>
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickyfractioncell,
                      )}
                      align='left'
                    >
                      {row.fraction}
                    </TableCell>
                    <HandleMisc misc={row?.misc} />
                    {row.cells?.map((cell, cellIndex) => (
                      <>
                        <Tooltip
                          key={`required-cell-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}-${cell.year}-${cell.week}`}
                          classes={{ tooltip: classes.tooltip }}
                          title={getComment(row, cell)}
                        >
                          <TableCell
                            align='left'
                            className={classes.cell}
                            onMouseOver={() => addToSelected(row, cell)}
                            ref={inputBoxRef}
                          >
                            <div
                              style={{
                                flexDirection: 'row',
                                display: 'flex',
                                height: '100%',
                              }}
                            >
                              {cellIndex === 0 && (
                                <div>
                                  <AddToRow
                                    onAddClicked={(value) =>
                                      handleOnAddToAllCells(row, value)
                                    }
                                  />
                                </div>
                              )}
                              <div>
                                <TableInput
                                  isSelected={
                                    !!selectedElemets.find(
                                      (el) =>
                                        el.dimension === row.dimension &&
                                        el.senderCountryId ===
                                          row.senderCountryId &&
                                        el.transportationId ===
                                          row.transportationId &&
                                        el.fractionId === row.fractionId &&
                                        el.misc === row.misc &&
                                        el.year === cell.year &&
                                        el.week === cell.week,
                                    )
                                  }
                                  id={`required-input-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}-${cell.year}-${cell.week}`}
                                  select={() => updateSelectElements(row, cell)}
                                  bgColor={getBackgroundColor(row, cell)}
                                  value={cell.value}
                                  onChange={(e) =>
                                    updateValue(
                                      row.dimension,
                                      row.senderCountryId,
                                      row.transportationId,
                                      row.fractionId,
                                      cellIndex,
                                      e,
                                      row.misc,
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </TableCell>
                        </Tooltip>
                      </>
                    ))}
                    <TableCell
                      align='center'
                      key='total required'
                      className={cn(
                        classes.readonlycell,
                        classes.bold,
                        classes.stickytotalcell,
                      )}
                    >
                      <NumberFormat
                        value={totalRow(row)}
                        readOnly
                        thousandSeparator={' '}
                        type='text'
                        displayType='text'
                        isNumericString
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {showPlanned && (
              <TableRow key='planned Row'>
                <TableCell
                  className={cn(
                    classes.readonlycell,
                    classes.stickycountrycell,
                    classes.bold,
                  )}
                  align='left'
                  colSpan={showMisc ? 3 : 2}
                >
                  Planned
                </TableCell>
                <TableCell className={cn(classes.readonlycell, classes.bold)} />
                {newData.plannedRows[0]?.cells.length > 0 &&
                  newData.plannedRows[0]?.cells.map((cell, cellIndex) => (
                    <TableCell
                      align='center'
                      key={`planned-total-${cell.year}-${cell.week}`}
                      className={cn(classes.readonlycell, classes.bold)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {cellIndex === 0 && (
                          <div
                            style={{
                              width: 25,
                            }}
                          ></div>
                        )}
                        <NumberFormat
                          value={totalColumn(newData.plannedRows, cellIndex)}
                          readOnly
                          thousandSeparator={' '}
                          type='text'
                          displayType='text'
                          isNumericString
                        />
                      </div>
                    </TableCell>
                  ))}
                <TableCell
                  align='center'
                  key='total total'
                  className={cn(
                    classes.readonlycell,
                    classes.bold,
                    classes.stickytotalcell,
                  )}
                >
                  <NumberFormat
                    value={dimensionTotal(newData.plannedRows)}
                    readOnly
                    thousandSeparator={' '}
                    type='text'
                    displayType='text'
                    isNumericString
                  />
                </TableCell>
              </TableRow>
            )}
            {showPlanned &&
              newData.plannedRows.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={`planned-row-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}`}
                  >
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickycountrycell,
                      )}
                      align='left'
                    >
                      {row.senderCountryId}
                    </TableCell>
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickytransportationcell,
                      )}
                      align='left'
                    >
                      {row.transportation}
                    </TableCell>
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickyfractioncell,
                      )}
                      align='left'
                    >
                      {row.fraction}
                    </TableCell>
                    <HandleMisc misc={row?.misc} />

                    {row.cells?.map((cell, cellIndex) => (
                      <Tooltip
                        key={`planned-cell-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}-${cell.year}-${cell.week}`}
                        classes={{ tooltip: classes.tooltip }}
                        title={getComment(row, cell)}
                      >
                        <TableCell
                          align='left'
                          className={classes.cell}
                          onMouseOver={() => addToSelected(row, cell)}
                        >
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              height: '100%',
                            }}
                          >
                            {cellIndex === 0 && (
                              <div>
                                <AddToRow
                                  onAddClicked={(value) =>
                                    handleOnAddToAllCells(row, value)
                                  }
                                />
                              </div>
                            )}
                            <div>
                              <TableInput
                                isSelected={
                                  !!selectedElemets.find(
                                    (el) =>
                                      el.dimension === row.dimension &&
                                      el.senderCountryId ===
                                        row.senderCountryId &&
                                      el.transportationId ===
                                        row.transportationId &&
                                      el.fractionId === row.fractionId &&
                                      el.misc === row.misc &&
                                      el.year === cell.year &&
                                      el.week === cell.week,
                                  )
                                }
                                id={`planned-input-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}-${cell.year}-${cell.week}`}
                                select={() => updateSelectElements(row, cell)}
                                bgColor={getBackgroundColor(row, cell)}
                                value={cell.value}
                                onChange={(e) =>
                                  updateValue(
                                    row.dimension,
                                    row.senderCountryId,
                                    row.transportationId,
                                    row.fractionId,
                                    cellIndex,
                                    e,
                                    row.misc,
                                  )
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                      </Tooltip>
                    ))}
                    <TableCell
                      align='center'
                      key='total planned'
                      className={cn(
                        classes.readonlycell,
                        classes.bold,
                        classes.stickytotalcell,
                      )}
                    >
                      <NumberFormat
                        value={totalRow(row)}
                        readOnly
                        thousandSeparator={' '}
                        type='text'
                        displayType='text'
                        isNumericString
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            {showDelivered && (
              <TableRow key='delivered Row'>
                <TableCell
                  className={cn(
                    classes.readonlycell,
                    classes.stickycountrycell,
                    classes.bold,
                  )}
                  align='left'
                  colSpan={showMisc ? 3 : 2}
                >
                  Delivered
                </TableCell>
                <TableCell className={cn(classes.readonlycell, classes.bold)} />
                {newData.deliveredRows[0]?.cells?.length > 0 &&
                  newData.deliveredRows[0].cells.map((cell, cellIndex) => (
                    <TableCell
                      align='center'
                      key={`delivered-total-${cell.year}-${cell.week}`}
                      className={cn(classes.readonlycell, classes.bold)}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        {cellIndex === 0 && (
                          <div
                            style={{
                              width: 25,
                            }}
                          ></div>
                        )}
                        <NumberFormat
                          value={totalColumn(newData.deliveredRows, cellIndex)}
                          readOnly
                          thousandSeparator={' '}
                          type='text'
                          displayType='text'
                          isNumericString
                        />
                      </div>
                    </TableCell>
                  ))}
                <TableCell
                  align='center'
                  key='total total'
                  className={cn(
                    classes.readonlycell,
                    classes.bold,
                    classes.stickytotalcell,
                  )}
                >
                  <NumberFormat
                    value={dimensionTotal(newData.deliveredRows)}
                    readOnly
                    thousandSeparator={' '}
                    type='text'
                    displayType='text'
                    isNumericString
                  />
                </TableCell>
              </TableRow>
            )}
            {showDelivered &&
              newData.deliveredRows?.map((row, rowIndex) => {
                return (
                  <TableRow
                    key={`delivered-row-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}`}
                  >
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickycountrycell,
                      )}
                      align='left'
                    >
                      {row.senderCountryId}
                    </TableCell>
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickytransportationcell,
                      )}
                      align='left'
                    >
                      {row.transportation}
                    </TableCell>
                    <TableCell
                      className={cn(
                        classes.readonlycell,
                        classes.stickyfractioncell,
                      )}
                      align='left'
                    >
                      {row.fraction}
                    </TableCell>
                    <HandleMisc misc={row?.misc} />

                    {row.cells?.map((cell, cellIndex) => (
                      <Tooltip
                        key={`delivered-cell-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}-${cell.year}-${cell.week}`}
                        classes={{ tooltip: classes.tooltip }}
                        title={getComment(row, cell)}
                      >
                        <TableCell
                          align='left'
                          className={classes.cell}
                          onMouseOver={() => addToSelected(row, cell)}
                        >
                          <div
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              height: '100%',
                            }}
                          >
                            {cellIndex === 0 && (
                              <div>
                                <AddToRow
                                  onAddClicked={(value) =>
                                    handleOnAddToAllCells(row, value)
                                  }
                                />
                              </div>
                            )}
                            <div>
                              <TableInput
                                isSelected={
                                  !!selectedElemets.find(
                                    (el) =>
                                      el.dimension === row.dimension &&
                                      el.senderCountryId ===
                                        row.senderCountryId &&
                                      el.transportationId ===
                                        row.transportationId &&
                                      el.fractionId === row.fractionId &&
                                      el.misc === row.misc &&
                                      el.year === cell.year &&
                                      el.week === cell.week,
                                  )
                                }
                                id={`delivered-input-${row.senderCountryId}-${row.transportationId}-${row.fraction}-${row.misc}-${cell.year}-${cell.week}`}
                                select={() => updateSelectElements(row, cell)}
                                bgColor={getBackgroundColor(row, cell)}
                                value={cell.value}
                                onChange={(e) =>
                                  updateValue(
                                    row.dimension,
                                    row.senderCountryId,
                                    row.transportationId,
                                    row.fractionId,
                                    cellIndex,
                                    e,
                                    row.misc,
                                  )
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                      </Tooltip>
                    ))}
                    <TableCell
                      align='center'
                      key='total delivered'
                      className={cn(
                        classes.readonlycell,
                        classes.bold,
                        classes.stickytotalcell,
                      )}
                    >
                      <NumberFormat
                        value={totalRow(row)}
                        readOnly
                        thousandSeparator={' '}
                        type='text'
                        displayType='text'
                        isNumericString
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <div className={styles.tableButtons}>
        <Button
          className={classes.savebutton}
          color='primary'
          variant='contained'
          onClick={() => onSave(data)}
        >
          Save all changes
        </Button>
        {selectedElemets?.length > 0 && commentMode !== ERROR_COMMENT && (
          <Button
            className={classes.commitbutton}
            color='primary'
            variant='contained'
            onClick={() => setCommentsModalOpen(true)}
          >
            {commentMode === NEW_COMMENT ? 'Add comment' : 'Edit comment'}
          </Button>
        )}
      </div>
      <div className={styles.sumView}>Selected Sum: {selectedSum}</div>
      <Modal
        open={commentsModalOpen}
        className={classes.modal}
        onClose={() => setCommentsModalOpen(false)}
        aria-labelledby='comment-modal'
        aria-describedby='see-and-edit-comments'
      >
        <div className={styles.modalWrppper}>
          <IconButton
            onClick={() => setCommentsModalOpen(false)}
            className={classes.closemodalbutton}
          >
            <CloseIcon className={classes.closeicon} />
          </IconButton>
          <CommentInput
            comment={selectedComment}
            onMessageChange={(e) =>
              updateSelectedCommentMessage(e.target.value)
            }
            onColorChange={(color) => updateSelectedCommentColor(color)}
          />
          {commentMode === UPDATE_COMMENT && (
            <div className={styles.buttonGroup}>
              <Button
                onClick={() => {
                  onUpdateComment(selectedComment);
                  setCommentsModalOpen(false);
                }}
                color='primary'
                variant='contained'
              >
                Update comment
              </Button>
              <Button
                onClick={() => {
                  onDeleteComment(selectedComment);
                  setCommentsModalOpen(false);
                }}
                color='primary'
                variant='contained'
              >
                delete comment
              </Button>
            </div>
          )}
          {commentMode === NEW_COMMENT && (
            <Button
              className={classes.savebutton}
              color='primary'
              variant='contained'
              onClick={() => {
                onPostComment(selectedComment);
                setCommentsModalOpen(false);
              }}
            >
              Add comment
            </Button>
          )}
          <div />
        </div>
      </Modal>
    </>
  );
};

export default ExpandedPlannedTable;
