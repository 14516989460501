/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles.module.scss';

interface ITableInputProps {
  id: string;
  isSelected: boolean;
  value: number;
  onChange: (e: number) => void;
  select: () => void;
  bgColor: string;
  readOnly?: boolean;
}

const TableInput = ({
  value,
  onChange,
  isSelected = false,
  select,
  bgColor,
  readOnly,
}: ITableInputProps): JSX.Element => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={styles.inputContainer}>
      <NumberFormat
        type='text'
        isNumericString
        value={localValue}
        readOnly={readOnly}
        onBlur={() => onChange(localValue)}
        onValueChange={(v) => setLocalValue(v.floatValue || 0)}
        onFocus={(event) => {
          event.target.select();
          select();
        }}
        style={{ backgroundColor: bgColor, height: '100%' }}
        className={cn(
          styles.input,
          isSelected && !readOnly && styles.selectedInput,
        )}
      />
    </div>
  );
};

TableInput.defaultProps = {
  readOnly: false,
};

export default TableInput;
