import { makeStyles } from '@material-ui/core/styles';
import { alto, white, black } from 'styles/jsColors';

const jsStyles = makeStyles({
  tablehead: {
    backgroundColor: alto,
  },
  tablebody: {
    backgroundColor: white,
  },

  headercell: {
    fontSize: '14px',
    fontWeight: 'bold',
  },

  stickyheadercell: {
    position: 'sticky',
    left: '0',
    backgroundColor: alto,
    padding: '0 1rem',
    height: '40px',
    minWidth: '150px',
    fontSize: '14px',
  },

  stickytotalcell: {
    position: 'sticky',
    backgroundColor: alto,
    right: 0,
  },

  typecell: {
    position: 'sticky',
    left: '0',
    backgroundColor: white,
    padding: '0 1rem',
    height: '40px',
    minWidth: '200px',
    fontSize: '16px',
    fontWeight: 'bold',
  },

  cell: {
    padding: '0 1rem',
    height: '40px',
    minWidth: '60px',
    fontSize: '14px',
  },

  readonlycell: {
    padding: '0 1rem',
    height: '40px',
    minWidth: '75px',
    fontSize: '14px',
    backgroundColor: alto,
  },

  tablefootercell: {
    backgroundColor: alto,
    color: black,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '0 16px',
  },

  negativcell: {
    backgroundColor: '#e6a8a8',
  },
  positivecell: {
    backgroundColor: '#d1e6c2',
  },
});

export default jsStyles;
