import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  h2: {
    fontSize: '26px',
    fontWeight: 'bold',
  },
});

export default jsStyles;
