import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { appInsightsConfig } from './config/appInsightsConfig';
import { appSettings } from './config/appSettings';

export const enableAppInsights = (): void => {
  if (!appSettings.isDevelopment) {
    const appInsights = new ApplicationInsights(appInsightsConfig);
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
};

export default enableAppInsights;
