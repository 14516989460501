/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  PageHeader,
  PageLayout,
  DropdownMenu,
  CompareTable,
  Datepicker,
  CommentSection,
} from 'components';
import { useAuth } from '@innit/msal-browser-react';
import { getAllCountries, getAllForecasts } from 'services/Api';
import { ICountry, IFacility } from 'interfaces/Country';
import {
  IPlannedForecastTable,
  IPlannedTableFacility,
} from 'interfaces/PlannedTable';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
  DRILLDOWN_COUNTRY,
  DRILLDOWN_FACILITIES,
} from 'utils/LocalStorageKeys';
import jsStyles from './jsStyles';
import styles from './styles.module.scss';
import { SelectAllControl } from 'utils/Selection';

interface ParamTypes {
  urlCountry: string;
  urlFacilities: string;
}

const DrilldownPage = (): JSX.Element => {
  const { push } = useHistory();
  const { urlCountry, urlFacilities } = useParams<ParamTypes>();
  const { getToken } = useAuth();
  const classes = jsStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const [availableCountries, setAvailableCountries] = useState<ICountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();

  const [availableFacilities, setAvailableFacilities] = useState<IFacility[]>(
    [],
  );

  const [selectedFacilities, setSelectedFacilities] = useState<
    IPlannedTableFacility[]
  >([]);

  const [forecastTables, setForecastTables] = useState<IPlannedForecastTable[]>(
    [],
  );

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(
    new Date(new Date().getFullYear(), 11, 31),
  );

  const [showBudget, setShowBudget] = useState<boolean>(true);
  const [showRequired, setShowRequired] = useState<boolean>(true);
  const [showPlanned, setShowPlanned] = useState<boolean>(true);

  useEffect(() => {
    if (selectedFacilities?.length > 0) {
      const facilities = selectedFacilities.map((facility) => facility.id);
      localStorage.setItem(DRILLDOWN_FACILITIES, JSON.stringify(facilities));
      push(`/drilldown/${selectedCountry?.id}/${facilities}`);
    } else if (selectedCountry) {
      push(`/drilldown/${selectedCountry?.id}`);
    } else {
      push(`/drilldown`);
    }
  }, [selectedFacilities]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const { data } = await getAllCountries(token);
        setAvailableCountries(data);
        if (urlCountry) {
          const getUrlCountryFromData: ICountry = data.find(
            (country: ICountry) =>
              country.id.toLowerCase() === urlCountry.toLowerCase(),
          );
          setSelectedCountry(getUrlCountryFromData);
          setAvailableFacilities(getUrlCountryFromData?.facilities || []);
          if (urlFacilities) {
            const urlFacilitiesArray = urlFacilities.split(',');
            const getUrlFacilitiesFromData = getUrlCountryFromData.facilities.filter(
              (item) => urlFacilitiesArray.includes(item.id),
            );
            setSelectedFacilities(getUrlFacilitiesFromData);
          }
        } else {
          const localStorageCountry = localStorage.getItem(DRILLDOWN_COUNTRY);
          if (localStorageCountry) {
            const getLocalStorageCountryFromData: ICountry = data.find(
              (country: ICountry) =>
                country.id.toLowerCase() === localStorageCountry.toLowerCase(),
            );
            setSelectedCountry(getLocalStorageCountryFromData);
            setAvailableFacilities(
              getLocalStorageCountryFromData?.facilities || [],
            );
            push(`/drilldown/${getLocalStorageCountryFromData?.id}`);

            const localStorageFacilitiesArray = JSON.parse(
              localStorage.getItem(DRILLDOWN_FACILITIES) || '',
            );
            if (localStorageFacilitiesArray) {
              const getLocalStorageFacilitiesFromData = getLocalStorageCountryFromData.facilities.filter(
                (item) => localStorageFacilitiesArray.includes(item.id),
              );
              setSelectedFacilities(getLocalStorageFacilitiesFromData);
            }
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchAllForecasts = async (facilities: IPlannedTableFacility[]) => {
      try {
        setLoading(true);
        const token = await getToken();
        const { data } = await getAllForecasts(
          token,
          facilities.map((facility) => facility.id),
          startDate.toISOString(),
          endDate.toISOString(),
        );
        setForecastTables(data);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedFacilities?.length > 0) {
      fetchAllForecasts(selectedFacilities);
    } else {
      setForecastTables([]);
    }
  }, [selectedFacilities, startDate, endDate]);

  const selectCountry = (value: string) => {
    const selected = availableCountries.find(
      (country) => country.name === value,
    );
    if (!selected?.id) {
      localStorage.removeItem(DRILLDOWN_COUNTRY);
    } else {
      localStorage.setItem(DRILLDOWN_COUNTRY, selected?.id);
    }
    setSelectedCountry(selected);
    push(`/drilldown/${selected?.id}`);
    setAvailableFacilities(selected?.facilities || []);
    setSelectedFacilities([]);
  };

  const handlleOnFacilitiesChange = (
    checked: boolean,
    facilitiy: IPlannedTableFacility,
  ) => {
    if (checked) {
      setSelectedFacilities((prev) => [...prev, facilitiy]);
    } else {
      const removeFacility = selectedFacilities.filter(
        (selecetdFacility) => selecetdFacility.id !== facilitiy.id,
      );
      setSelectedFacilities(removeFacility);
      if (removeFacility?.length <= 0) {
        localStorage.removeItem(DRILLDOWN_FACILITIES);
      }
    }
  };

  const checkAllFacilities = () => {
    setSelectedFacilities([]);
    setSelectedFacilities(availableFacilities);
  };

  const clearAllFacilities = () => {
    setSelectedFacilities(() => {
      setForecastTables([]);
      localStorage.removeItem(DRILLDOWN_FACILITIES);
      return [];
    });
  };

  return (
    <PageLayout loading={loading}>
      <PageHeader title='Drilldown' />
      <div className={styles.dropDownsWrapper}>
        <div className={styles.countryButtonGrid}>
          <div className={styles.dropDownMargin}>
            <DropdownMenu
              selected={selectedCountry?.name || ''}
              disabled={!(availableCountries?.length > 0)}
              options={
                availableCountries
                  ? availableCountries?.map((country) => country.name)
                  : []
              }
              updateSelected={(value: string) => selectCountry(value)}
              label='Country'
            />
          </div>
        </div>
        <div className={styles.facilitiesDropDown}>
          <FormControl
            component='fieldset'
            className={styles.facilitiesDropDown}
          >
            <div className={classes.facilitylables}>
              <SelectAllControl
                totalItems={availableFacilities.length}
                onSelected={(isSelectedAll: boolean) =>
                  isSelectedAll ? checkAllFacilities() : clearAllFacilities()
                }
              >
                <FormLabel component='legend' className={classes.facilitylabel}>
                  Facilities
                </FormLabel>
              </SelectAllControl>
            </div>
            <FormGroup aria-label='facilities' row>
              {availableFacilities?.map((facility) => (
                <FormControlLabel
                  className={classes.facilities}
                  key={facility.id}
                  checked={selectedFacilities.some(
                    (fac) => fac.id === facility.id,
                  )}
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      onChange={(event) =>
                        handlleOnFacilitiesChange(
                          event.target.checked,
                          facility,
                        )
                      }
                      color='primary'
                    />
                  }
                  label={facility.name}
                  labelPlacement='end'
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
        <div className={styles.dateGrid}>
          <div className={styles.dropDownMargin}>
            <Datepicker
              lable='Start date'
              date={startDate}
              setDate={(date: Date | null) =>
                date && setStartDate(new Date(date))
              }
            />
          </div>
          <div className={styles.dropDownMargin}>
            <Datepicker
              lable='End date'
              date={endDate}
              setDate={(date: Date | null) =>
                date && setEndDate(new Date(date))
              }
              minDate={startDate}
            />
          </div>
        </div>
        <div className={styles.dataDimentionsDropDown}>
          <div className={styles.dropDownMargin}>
            <FormControl component='fieldset'>
              <SelectAllControl
                totalItems={3}
                onSelected={(isSelectedAll: boolean) => {
                  if (isSelectedAll) {
                    setShowBudget(true);
                    setShowRequired(true);
                    setShowPlanned(true);
                  } else {
                    setShowBudget(false);
                    setShowRequired(false);
                    setShowPlanned(false);
                  }
                }}
              >
                <FormLabel className={classes.facilitylabel} component='legend'>
                  Data dimensions
                </FormLabel>
              </SelectAllControl>
              <FormGroup aria-label='filter' row>
                <FormControlLabel
                  key='budget-filter'
                  checked={showBudget}
                  control={
                    <Checkbox
                      onChange={(event) => setShowBudget(event.target.checked)}
                      color='primary'
                    />
                  }
                  label='Delivery plan'
                  labelPlacement='end'
                />
                <FormControlLabel
                  key='required-filter'
                  checked={showRequired}
                  control={
                    <Checkbox
                      onChange={(event) =>
                        setShowRequired(event.target.checked)
                      }
                      color='primary'
                    />
                  }
                  label='Required'
                  labelPlacement='end'
                />
                <FormControlLabel
                  key='planned-filter'
                  checked={showPlanned}
                  control={
                    <Checkbox
                      onChange={(event) => setShowPlanned(event.target.checked)}
                      color='primary'
                    />
                  }
                  label='Planned'
                  labelPlacement='end'
                />
              </FormGroup>
            </FormControl>
          </div>
        </div>
      </div>

      {forecastTables?.length > 0 &&
        forecastTables.map((forecast) => (
          <div key={forecast.facility.id} className={styles.facilityWrapper}>
            <div className={styles.header}>
              <div className={styles.headerTitle}>
                <Typography classes={classes} variant='h2' component='h1'>
                  Forecast for {forecast.facility.name}
                </Typography>
              </div>
            </div>
            <div className={styles.forecastContent}>
              <CompareTable
                table={forecast}
                showBudget={showBudget}
                showPlanned={showPlanned}
                showRequired={showRequired}
              />
              <CommentSection
                startDate={startDate}
                endDate={endDate}
                facility={forecast.facility}
              />
            </div>
          </div>
        ))}
    </PageLayout>
  );
};

export default DrilldownPage;
