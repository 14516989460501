import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const jsStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
  }),
);

export default jsStyles;
