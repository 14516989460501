/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Modal,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import cn from 'classnames';
import {
  IBudget,
  ITransportation,
  ITableValue,
  ISlectedBudgetCell,
} from 'interfaces/BudgetTable';
import NumberFormat from 'react-number-format';
import CloseIcon from '@material-ui/icons/Close';
import { IComment } from 'interfaces/Comment';
import { white } from 'styles/jsColors';
import CommentInput from 'components/CommentInput/CommentInput';
import AddToRow from 'components/AddToRow/AddToRow';
import TableInput from '../TableInput/TableInput';
import jsStyles from './jsStyles';
import styles from './styles.module.scss';

type BudgetTableProps = {
  table: IBudget;
  comments: IComment[];
  onSave: any;
  onPostComment: (comment?: IComment) => void;
  onUpdateComment: (comment?: IComment) => void;
  onDeleteComment: (comment?: IComment) => void;
};

const NEW_COMMENT = 'new_comment';
const UPDATE_COMMENT = 'update_comment';
const ERROR_COMMENT = 'error_comment';

const BudgetTable = ({
  table,
  comments = [],
  onSave,
  onPostComment,
  onUpdateComment,
  onDeleteComment,
}: BudgetTableProps): JSX.Element => {
  const classes = jsStyles();
  const [data, setData] = useState<ITransportation[]>(table.transportation);
  const [commentsModalOpen, setCommentsModalOpen] = useState<boolean>(false);
  const [selectedElemets, setSelectedElemets] = useState<ISlectedBudgetCell[]>(
    [],
  );
  const [commentMode, setCommentMode] = useState<string>(NEW_COMMENT);
  const [selectedComment, setSelectedComment] = useState<IComment>();

  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

  const [selectedSum, setSelectedSum] = useState<number>(0);

  useEffect(() => {
    const sum = selectedElemets.reduce((a, b) => a + b.value, 0);
    setSelectedSum(sum);
  }, [selectedElemets]);

  useEffect(() => {
    const checkCommentInSelected = () => {
      if (selectedElemets?.length > 0) {
        if (comments?.length > 0) {
          const search = {
            dimension: selectedElemets[0].dimension,
            fractionId: selectedElemets[0].fractionId,
            transportationId: selectedElemets[0].transportationId,
            weekFrom: selectedElemets[0].week,
            weekTo: selectedElemets[selectedElemets.length - 1].week,
          };
          let commentsCounter = 0;

          selectedElemets.forEach((element) => {
            if (
              comments.some(
                (comment) =>
                  comment.transportId === search.transportationId &&
                  comment.dimension === search.dimension &&
                  Number(comment.weekFrom) <= Number(element.week) &&
                  Number(comment.weekTo) >= Number(element.week) &&
                  comment.fractionId === element.fractionId,
              )
            ) {
              commentsCounter += 1;
            }
          });

          if (commentsCounter === 0) {
            setCommentMode(NEW_COMMENT);
            const tempComment = {
              dimension: selectedElemets[0].dimension,
              transportId: selectedElemets[0].transportationId,
              senderId: selectedElemets[0].senderCountryId,
              fractionId: selectedElemets[0].fractionId,
              color: '#FC9079',
              message: '',
              weekFrom: selectedElemets[0].week,
              weekTo: selectedElemets[selectedElemets.length - 1].week,
              yearFrom: selectedElemets[0].year,
              yearTo: selectedElemets[selectedElemets.length - 1].year,
            };
            setSelectedComment(tempComment);
          } else if (commentsCounter === selectedElemets.length) {
            const c = comments.filter(
              (comment) =>
                comment.transportId === search.transportationId &&
                comment.dimension === search.dimension &&
                Number(comment.weekFrom) <= Number(search.weekFrom) &&
                Number(comment.weekTo) >= Number(search.weekTo) &&
                comment.fractionId === search.fractionId,
            );
            if (c.length === 1) {
              setSelectedComment(c[0]);
              setCommentMode(UPDATE_COMMENT);
            } else {
              setCommentMode(ERROR_COMMENT);
            }
          } else {
            setCommentMode(ERROR_COMMENT);
          }
        } else {
          setCommentMode(NEW_COMMENT);
          const tempComment = {
            dimension: selectedElemets[0].dimension,
            transportId: selectedElemets[0].transportationId,
            senderId: selectedElemets[0].senderCountryId,
            fractionId: selectedElemets[0].fractionId,
            color: '#FC9079',
            message: '',
            weekFrom: selectedElemets[0].week,
            weekTo: selectedElemets[selectedElemets.length - 1].week,
            yearFrom: selectedElemets[0].year,
            yearTo: selectedElemets[selectedElemets.length - 1].year,
          };
          setSelectedComment(tempComment);
        }
      }
    };
    checkCommentInSelected();
  }, [selectedElemets, comments]);

  const updateValue = (number: number, object: number, week: number) => {
    if (!Number.isNaN(number)) {
      const tempData = data;
      tempData[object].values[week].value = number;
      setData([...tempData]);
    }
  };

  const getBackgroundColor = (cell: ITableValue, row: ITransportation) => {
    let backgroundColor = white;
    if (comments?.length > 0) {
      const commentExsists = comments.find(
        (comment) =>
          comment.dimension === cell.dimension &&
          Number(comment.weekFrom) <= Number(cell.week) &&
          Number(comment.weekTo) >= Number(cell.week) &&
          comment.fractionId === row.fraction.id &&
          comment.transportId === String(row.id) &&
          comment.senderId === String(row.senderCountry.id),
      );
      backgroundColor = commentExsists?.color || white;
    }

    return backgroundColor;
  };

  const getComment = (cell: ITableValue, row: ITransportation) => {
    let commentMessage = '';
    if (comments?.length > 0) {
      const commentExsists = comments.find(
        (comment) =>
          comment.dimension === cell.dimension &&
          Number(comment.weekFrom) <= Number(cell.week) &&
          Number(comment.weekTo) >= Number(cell.week) &&
          comment.fractionId === row.fraction.id &&
          comment.transportId === String(row.id) &&
          comment.senderId === String(row.senderCountry.id),
      );
      commentMessage = commentExsists?.message || '';
    }

    return commentMessage;
  };

  const updateSelectElements = (cell: ITableValue, row: ITransportation) => {
    const element = {
      id: cell.id,
      value: cell.value,
      fractionId: row.fraction.id,
      transportationId: String(row.id),
      senderCountryId: row.senderCountry.id,
      week: cell.week,
      year: cell.year,
      dimension: cell.dimension,
    };

    if (!selectedElemets.some((el) => el.week === element.week)) {
      setSelectedElemets(
        [...selectedElemets, element].sort(
          (a, b) => Number(a.week) - Number(b.week),
        ),
      );
    }
  };

  const updateSelectedCommentMessage = (newMessage: string) => {
    const tempComment = { ...selectedComment, message: newMessage };
    setSelectedComment(tempComment);
  };

  const updateSelectedCommentColor = (newColor: string) => {
    const tempComment = { ...selectedComment, color: newColor };
    setSelectedComment(tempComment);
  };

  const addToSelected = (cell: ITableValue, row: ITransportation) => {
    if (isMouseDown) {
      if (
        (selectedElemets?.length > 0 &&
          selectedElemets[0].senderCountryId === String(row.senderCountry.id) &&
          selectedElemets[0].transportationId === String(row.id) &&
          selectedElemets[0].fractionId === row.fraction.id) ||
        selectedElemets?.length === 0
      ) {
        updateSelectElements(cell, row);
      }
    }
  };

  const totalRow = (row: ITransportation) => {
    return row?.values?.reduce((sum, item) => {
      return sum + (item.isDataPadding ? 0 : item.value);
    }, 0);
  };

  const totalColumn = (
    columnIndex: number,
    transportation: ITransportation[],
  ) =>
    transportation.reduce(
      (sum, item) => sum + item.values[columnIndex].value ?? 0,
      0,
    );

  const totalTotalColumn = (transportation: ITransportation[]) => {
    return transportation.reduce(
      (total, transport) =>
        total +
        transport.values.reduce(
          (sum: number, item: any) =>
            sum + (item.isDataPadding ? 0 : item.value),
          0,
        ),
      0,
    );
  };

  const handleOnAddToRow = (value: number, rowIndex: number) => {
    const tempData = data;

    tempData[rowIndex].values.map((cell) => {
      if (!cell.isDataPadding) {
        // eslint-disable-next-line no-param-reassign
        cell.value = value;
      }
      return cell;
    });
    setData([...tempData]);
  };

  const scrollCell = () => {
    const tableElement = document.getElementById('tableWrapper');
    if (tableElement !== null) {
      tableElement.scrollLeft += 103;
    }
  };

  const enterDragMode = () => {
    setSelectedElemets([]);
    setIsMouseDown(true);
  };

  return (
    <>
      <div
        className={styles.tableWrapper}
        id='tableWrapper'
        onMouseDown={enterDragMode}
        onMouseUp={() => setIsMouseDown(false)}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            setSelectedElemets([]);
            scrollCell();
          }
        }}
      >
        <Table aria-label='Budget table'>
          <TableHead className={classes.tablehead}>
            <TableRow>
              <TableCell
                className={cn(classes.headercell, classes.stickycountrycell)}
                align='left'
              >
                Country
              </TableCell>
              <TableCell
                className={cn(
                  classes.headercell,
                  classes.stickytransportationcell,
                )}
                align='left'
              >
                Transportation
              </TableCell>
              <TableCell
                className={cn(classes.headercell, classes.stickyfractioncell)}
                align='left'
              >
                Fraction
              </TableCell>
              {data?.length > 0 &&
                data[0].values?.length > 0 &&
                data[0].values.map((_, i) => (
                  <TableCell
                    className={classes.headercell}
                    style={{ paddingLeft: i === 0 ? 25 : 0 }}
                    key={`header-week ${i + 1}`}
                    align='center'
                  >{`Week ${i + 1}`}</TableCell>
                ))}
              <TableCell
                className={cn(classes.headercell, classes.stickytotalcell)}
                align='center'
              >
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tablebody}>
            {data?.length > 0 &&
              data.map((row, j) => (
                <TableRow
                  key={`${row.id} - ${row.senderCountry.id} - ${row.fraction.id}`}
                >
                  <TableCell
                    className={cn(classes.cell, classes.stickycountrycell)}
                    align='left'
                  >
                    {row.senderCountry.id}
                  </TableCell>
                  <TableCell
                    className={cn(
                      classes.cell,
                      classes.stickytransportationcell,
                    )}
                    align='left'
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    className={cn(classes.cell, classes.stickyfractioncell)}
                    align='left'
                  >
                    {row.fraction.name}
                  </TableCell>
                  {row?.values?.length > 0 &&
                    row.values.map((cell, i) => (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={getComment(cell, row)}
                        key={cell.id}
                      >
                        <TableCell
                          key={cell.id}
                          align='left'
                          className={classes.cell}
                          onMouseOver={() => addToSelected(cell, row)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              height: '100%',
                            }}
                          >
                            {!table.locked && i === 0 && (
                              <AddToRow
                                onAddClicked={(value) =>
                                  handleOnAddToRow(value, j)
                                }
                                disabled={table.locked}
                              />
                            )}
                            {cell.isDataPadding ? (
                              <div className={styles.nonExisting}>
                                <p className={styles.nonExistingLable}>
                                  Non-existing
                                </p>
                              </div>
                            ) : (
                              <div>
                                <TableInput
                                  isSelected={
                                    !!selectedElemets.find(
                                      (el) => el.id === cell.id,
                                    )
                                  }
                                  readOnly={table.locked}
                                  bgColor={getBackgroundColor(cell, row)}
                                  select={() => updateSelectElements(cell, row)}
                                  id={cell.id}
                                  value={cell.value}
                                  onChange={(e) => updateValue(e, j, i)}
                                />
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </Tooltip>
                    ))}
                  <TableCell
                    align='center'
                    className={cn(
                      classes.tablefootercell,
                      classes.stickytotalcell,
                    )}
                  >
                    <NumberFormat
                      value={totalRow(row)}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell
                colSpan={2}
                padding='none'
                size='small'
                align='left'
                className={cn(
                  classes.tablefootercell,
                  classes.stickycountrycell,
                )}
              >
                Total
              </TableCell>
              <TableCell className={cn(classes.tablefootercell)} />
              {data?.length > 0 &&
                data[0].values?.length > 0 &&
                data[0].values.map((_, i) => (
                  <TableCell
                    key={`footer-week ${i + 1}`}
                    className={classes.tablefootercell}
                    align='center'
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {i === 0 && (
                        <div
                          style={{
                            width: 25,
                          }}
                        ></div>
                      )}
                      <NumberFormat
                        value={totalColumn(i, data)}
                        readOnly
                        thousandSeparator={' '}
                        type='text'
                        displayType='text'
                        isNumericString
                      />
                    </div>
                  </TableCell>
                ))}
              <TableCell
                key='footer-Total'
                className={cn(classes.tablefootercell, classes.stickytotalcell)}
                align='center'
              >
                <NumberFormat
                  value={totalTotalColumn(data)}
                  readOnly
                  thousandSeparator={' '}
                  type='text'
                  displayType='text'
                  isNumericString
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </div>
      <div className={styles.tableButtons}>
        <Button
          onClick={() => onSave(data, false)}
          className={classes.savebutton}
          color='primary'
          variant='contained'
          disabled={table.locked}
        >
          Save changes
        </Button>
        <Button
          disabled={table.locked}
          variant='contained'
          className={classes.commitbutton}
          onClick={() => onSave(data, true)}
        >
          Commit changes to required and planned
        </Button>
        {selectedElemets?.length > 0 && commentMode !== ERROR_COMMENT && (
          <Button
            className={classes.commitbutton}
            color='primary'
            variant='contained'
            onClick={() => setCommentsModalOpen(true)}
          >
            {commentMode === NEW_COMMENT ? 'Add comment' : 'Edit comment'}
          </Button>
        )}
      </div>
      <div className={styles.sumView}>
        Selected Sum: {table.locked ? 0 : selectedSum}
      </div>
      <Modal
        open={commentsModalOpen}
        className={classes.modal}
        onClose={() => setCommentsModalOpen(false)}
        aria-labelledby='comment-modal'
        aria-describedby='see-and-edit-comments'
      >
        <div className={styles.modalWrppper}>
          <IconButton
            onClick={() => setCommentsModalOpen(false)}
            className={classes.closemodalbutton}
          >
            <CloseIcon className={classes.closeicon} />
          </IconButton>
          <CommentInput
            comment={selectedComment}
            onMessageChange={(e) =>
              updateSelectedCommentMessage(e.target.value)
            }
            onColorChange={(color) => updateSelectedCommentColor(color)}
          />
          {commentMode === UPDATE_COMMENT && (
            <div className={styles.buttonGroup}>
              <Button
                onClick={() => {
                  onUpdateComment(selectedComment);
                  setCommentsModalOpen(false);
                }}
                color='primary'
                variant='contained'
              >
                Update comment
              </Button>
              <Button
                onClick={() => {
                  onDeleteComment(selectedComment);
                  setCommentsModalOpen(false);
                }}
                color='primary'
                variant='contained'
              >
                delete comment
              </Button>
            </div>
          )}
          {commentMode === NEW_COMMENT && (
            <Button
              className={classes.savebutton}
              color='primary'
              variant='contained'
              onClick={() => {
                onPostComment(selectedComment);
                setCommentsModalOpen(false);
              }}
            >
              Add comment
            </Button>
          )}
          <div />
        </div>
      </Modal>
    </>
  );
};

export default BudgetTable;
