import React from 'react';
import ReactLoading from 'react-loading';
import Sidebar from '../Sidebar/Sidebar';
import styles from './styles.module.scss';

type PageLayoutProps = {
  children: React.ReactNode;
  loading?: boolean;
};

const PageLayout = ({ children, loading }: PageLayoutProps): JSX.Element => (
  <>
    <div className={styles.pageContainer}>
      <div className={styles.sidebar}>
        <Sidebar />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
    {loading && (
      <div className={styles.loadingWrapper}>
        <ReactLoading type='spin' height={150} width={150} />
      </div>
    )}
  </>
);

PageLayout.defaultProps = {
  loading: false,
};

export default PageLayout;
