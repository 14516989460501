/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';
import jsStyles from './jsStyles';

type DropdownProps = {
  options: string[];
  selected: string;
  updateSelected: (value: string) => void;
  label: string;
  disabled?: boolean;
};

const DropdownMenu = ({
  updateSelected,
  options,
  label,
  selected,
  disabled = false,
}: DropdownProps): JSX.Element => {
  const classes = jsStyles();

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    updateSelected(event.target.value);
  };

  return (
    <FormControl
      variant='outlined'
      classes={{ root: classes.formControl }}
      disabled={disabled}
    >
      <InputLabel className={classes.text} id='select-outlined-label'>
        {label}
      </InputLabel>
      <Select
        variant='outlined'
        id='custom-css-outlined-input'
        classes={{ outlined: classes.outlined }}
        value={selected}
        onChange={handleChange}
        label={label}
      >
        <MenuItem value=''>
          <Typography className={classes.text}>None</Typography>
        </MenuItem>
        {options &&
          options?.length > 0 &&
          options.map((item) => (
            <MenuItem key={item} value={item}>
              <Typography className={classes.text}>{item}</Typography>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DropdownMenu;
