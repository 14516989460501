import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as PlanningIcon } from 'assets/icons/planning-ico.svg';
import { ReactComponent as BudgetIcon } from 'assets/icons/budget-ico.svg';
// import { ReactComponent as CompareIcon } from 'assets/icons/compare-ico.svg';
import geminorWhiteLogo from 'assets/images/geminor-white.png';
import { appSettings } from 'config/appSettings';

import styles from './styles.module.scss';

const MenuLinks = [
  {
    title: 'Delivery plan',
    url: '/budget',
    icon: <BudgetIcon className={styles.icon} />,
  },
  {
    title: 'Planning',
    url: '/planning',
    icon: <PlanningIcon className={styles.icon} />,
  },
  // {
  //   title: 'Drilldown',
  //   url: '/drilldown',
  //   icon: <CompareIcon className={styles.icon} />,
  // },
];

const Sidebar = (): JSX.Element => {
  return (
    <div className={styles.sidebarContainer}>
      <div>
        <div className={styles.logoContainer}>
          <img
            className={styles.logo}
            src={geminorWhiteLogo}
            alt='Geminor-logo'
          />
        </div>
        {MenuLinks?.length > 0 &&
          MenuLinks.map(({ title, url, icon }) => (
            <Link key={title} to={url} className={styles.sidebarLink}>
              {icon}
              <p className={styles.sidebarLinkTitle}>{title}</p>
            </Link>
          ))}
      </div>
      {!appSettings.isProduction && <p>Staging!</p>}
    </div>
  );
};

export default Sidebar;
