import { Typography } from '@material-ui/core';
import React from 'react';
import styles from './styles.module.scss';

interface IErrorPageProps {
  code: number;
  reason: string;
}

const ErrorPage = ({ code, reason }: IErrorPageProps): JSX.Element => {
  return (
    <div className={styles.page}>
      <Typography variant='h1'>{code.toString()}</Typography>
      <Typography variant='h2'>{reason}</Typography>
    </div>
  );
};

ErrorPage.defaultProps = {
  code: 500,
  reason: 'Unknown Error',
};

export default ErrorPage;
