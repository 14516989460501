import React, { useState } from 'react';
import {
  Popover,
  Typography,
  Avatar,
  Button,
  Divider,
} from '@material-ui/core';
import { useAuth } from '@innit/msal-browser-react';
import jsStyles from './jsStyles';
import styles from './styles.module.scss';

const ProfileTab = (): JSX.Element => {
  const { user, logout, login } = useAuth();
  const classes = jsStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderLoggedIn = () => (
    <div className={styles.profileTab}>
      <Divider orientation='vertical' flexItem />
      <Button type='button' onClick={handleClick}>
        <div className={styles.card}>
          <div className={styles.cardImage}>
            <Avatar alt='No Img' />
          </div>
          <Typography>{user?.name}</Typography>
        </div>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Button onClick={() => logout()}>
          <Typography className={classes.typography}>Logg ut</Typography>
        </Button>
      </Popover>
    </div>
  );

  const renderLoggedOut = () => (
    <div className={styles.profileTab}>
      <Divider orientation='vertical' flexItem />
      <div className={styles.card}>
        <div className={styles.cardImage}>
          <Avatar alt='No Img' />
        </div>
        <Button onClick={() => login()}>
          <Typography>Logg Inn</Typography>
        </Button>
      </div>
    </div>
  );

  return <div>{user ? renderLoggedIn() : renderLoggedOut()}</div>;
};

export default ProfileTab;
