import { Typography } from '@material-ui/core';
import { PageLayout } from 'components';
import React from 'react';
import styles from './styles.module.scss';

const Page404 = (): JSX.Element => {
  return (
    <PageLayout>
      <div className={styles.page}>
        <Typography variant='h1'>404</Typography>
        <Typography variant='h2'>Page Not Found</Typography>
      </div>
    </PageLayout>
  );
};

export default Page404;
