import React, { useState, useEffect } from 'react';
import { useAuth } from '@innit/msal-browser-react';
import { toast } from 'react-toastify';
import { getComments } from 'services/Api';
import { IComment } from 'interfaces/Comment';
import { IPlannedTableFacility } from 'interfaces/PlannedTable';
import moment from 'moment';
import styles from './styles.module.scss';

type ICommentSection = {
  facility: IPlannedTableFacility;
  startDate: Date;
  endDate: Date;
};

const CommentSection = ({
  facility,
  startDate,
  endDate,
}: ICommentSection): JSX.Element => {
  const [comments, setComments] = useState<IComment[]>([]);
  const { getToken } = useAuth();

  useEffect(() => {
    const fetchComments = async (facilityId?: string) => {
      try {
        const token = await getToken();
        const { data } = await getComments(
          token,
          facilityId,
          startDate.toISOString(),
          endDate.toISOString(),
        );
        setComments(data.comments);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(error?.response?.data?.detail);
      }
    };
    fetchComments(facility.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, facility.id, startDate]);

  const findDimention = (p: unknown) => {
    if (p === 'LOG_REQUIRED') {
      return 'Required';
    }
    if (p === 'LOG_PLANNED') {
      return 'Planned';
    }
    if (p === 'LOG_DELIV') {
      return 'Delivered';
    }
    return null;
  };

  return (
    <div className={comments?.length > 0 ? styles.commentSection : ``}>
      {comments &&
        comments.length > 0 &&
        comments.map((comment) => (
          <div className={styles.comment}>
            <div
              className={styles.commentHeader}
              style={{ backgroundColor: comment.color }}
            >
              <div className={styles.headerLeft}>
                <p>
                  {findDimention(comment.dimension)} Week: {comment.weekFrom}-
                  {comment.weekTo}
                </p>
              </div>
              <div className={styles.headerRight}>
                <p>
                  {comment.lastUpdate &&
                    moment(comment.lastUpdate)
                      .format('ddd. DD.MM.YYYY hh:mm')
                      .toString()}
                </p>
              </div>
            </div>
            <div className={styles.commentMessage}>
              <p>{comment.message}</p>
            </div>
            <div className={styles.commentFooter}>
              <p>-{comment.author}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CommentSection;
