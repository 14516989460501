import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import MainTheme from 'styles/MainTheme';
import Routes from 'router/Routes';
import { ToastContainer } from 'react-toastify';
import styles from './App.module.scss';
import 'react-toastify/dist/ReactToastify.css';

const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={MainTheme}>
      <div className={styles.app}>
        <Routes />
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
};

export default App;
