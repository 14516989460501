import React, { useState } from 'react';
import { Button, IconButton } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import jsStyles from './jsStyles';
import { MoreVertOutlined } from '@mui/icons-material';

import Popover from '@mui/material/Popover';

interface IAddToRowProps {
  onAddClicked: (value: number) => void;
  disabled?: boolean;
}

function PositionedMenu({ onAddClicked, disabled }: IAddToRowProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = jsStyles();
  const [localValue, setLocalValue] = useState<number>(0);

  return (
    <>
      <IconButton
        color='primary'
        aria-label='add to shopping cart'
        onClick={handleClick}
        style={{ maxWidth: 1 }}
      >
        <MoreVertOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ padding: 5 }}>
          <NumberFormat
            style={{ padding: 10 }}
            id='id'
            type='text'
            value={localValue}
            disabled={disabled}
            onValueChange={(v) => setLocalValue(v.floatValue || 0)}
            allowLeadingZeros={false}
            className={classes.input}
          />
          {`  `}
          <Button
            style={{
              fontWeight: 'bold',
            }}
            onClick={() => onAddClicked(localValue)}
            variant='contained'
            color='primary'
            disabled={disabled}
          >
            Add to all rows
          </Button>
        </div>
      </Popover>
    </>
  );
}

const AddToRow = ({ onAddClicked, disabled }: IAddToRowProps): JSX.Element => {
  return <PositionedMenu onAddClicked={onAddClicked} disabled={disabled} />;
};

AddToRow.defaultProps = {
  disabled: false,
};

export default AddToRow;
