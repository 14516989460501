import { makeStyles } from '@material-ui/core/styles';
import { white } from 'styles/jsColors';

const jsStyles = makeStyles({
  inputfield: {
    backgroundColor: white,
    borderRadius: 0,
    border: 'none',
    '& :focus': {
      outline: 0,
    },
  },
  input: {
    fontSize: '16px',
    outline: 0,
    borderRadius: 0,
    border: 'none',
    '& :focus': {
      outline: 0,
    },
  },
});

export default jsStyles;
