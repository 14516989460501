// budgetPage
export const BUDGET_COUNTRY = 'budgetCountry';
export const BUDGET_FACILITY = 'budgetFacility';
export const BUDGET_YEAR = 'budgetYear';

// planning
export const PLANNING_COUNTRY = 'planningCountry';
export const PLANNING_FACILITIES = 'planningFacilities';

// drilldown
export const DRILLDOWN_COUNTRY = 'drilldownCountry';
export const DRILLDOWN_FACILITIES = 'drilldownFacilities';
