/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import {
  PageHeader,
  PageLayout,
  DropdownMenu,
  ExpandedPlannedTable,
  Datepicker,
  CommentSection,
  CompareTable,
} from 'components';
import { useAuth } from '@innit/msal-browser-react';
import {
  getAllCountries,
  getPlanned,
  getAllForecasts,
  getComments,
  postComment,
  putComment,
  deleteComment,
  postPlanned,
  getForecastsFilterValues,
} from 'services/Api';
import { toast } from 'react-toastify';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  ArrowDropDown,
  ArrowRight,
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons';

import { ICountry, IFacility } from 'interfaces/Country';
import {
  IPlanningTableGetModel,
  IPlannedForecastTable,
  IPlannedTableFacility,
  IPlanningTablePutModel,
} from 'interfaces/PlannedTable';
import { IComment } from 'interfaces/Comment';
import { useHistory, useParams } from 'react-router-dom';
import { PLANNING_COUNTRY, PLANNING_FACILITIES } from 'utils/LocalStorageKeys';
import jsStyles from './jsStyles';

import styles from './styles.module.scss';
import { SelectAllControl } from 'utils/Selection';

interface ParamTypes {
  urlCountry: string;
  urlFacilities: string;
}

const PlanningPage = (): JSX.Element => {
  const { push } = useHistory();
  const { urlCountry, urlFacilities } = useParams<ParamTypes>();
  const { getToken } = useAuth();
  const classes = jsStyles();

  const [loading, setLoading] = useState<boolean>(false);
  const [expandedTable, setExpandedTable] = useState<IPlannedTableFacility>();
  const [comments, setComments] = useState<IComment[]>([]);
  const [availableCountries, setAvailableCountries] = useState<ICountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();
  const [availableFacilities, setAvailableFacilities] = useState<IFacility[]>(
    [],
  );
  const [selectedFacilities, setSelectedFacilities] = useState<
    IPlannedTableFacility[]
  >([]);

  const [forecastTables, setForecastTables] = useState<IPlannedForecastTable[]>(
    [],
  );

  const [tableUpdated, setTableUpdated] = useState<boolean>(false);

  const [
    // TODO
    detailedPlannedTable,
    setDetailedPlannedTable,
  ] = useState<IPlanningTableGetModel>();

  const [
    originDetailedPlannedTable,
    setOriginDetailedPlannedTable,
  ] = useState<IPlanningTableGetModel>();

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() + 1,
      new Date().getDate(),
    ),
  );

  interface IFiltersValues {
    sendingCountries: IFilter[];
    sendingFractions: IFilter[];
  }

  interface IFilter {
    id: string;
    name: string;
  }

  const [filterValues, setFilterValues] = useState<IFiltersValues | null>(null);

  const [checkedCountries, setCheckedCountries] = useState<IFilter[]>([]);
  const [filterCountries, setFilterCountries] = useState<IFilter[]>([]);

  const [checkedFractions, setCheckedFractions] = useState<IFilter[]>([]);
  const [filterFractions, setFilterFractions] = useState<IFilter[]>([]);

  const [checkedTransports, setCheckedTransports] = useState<string[]>([]);
  const [sendingTransports, setSendingTransports] = useState<string[]>([]);

  const [showBudget, setShowBudget] = useState<boolean>(true);
  const [showRequired, setShowRequired] = useState<boolean>(true);
  const [showPlanned, setShowPlanned] = useState<boolean>(true);
  const [showDelivered, setShowDelivered] = useState<boolean>(true);

  const [showBudgetPending, setShowBudgetPending] = useState<boolean>(true);
  const [showRequiredPending, setShowRequiredPending] = useState<boolean>(true);
  const [showPlannedPending, setShowPlannedPending] = useState<boolean>(true);
  const [showDeliveredPending, setShowDeliveredPending] = useState<boolean>(
    true,
  );

  const [sliderState, setSliderState] = useState<boolean>(true);
  const [sliderStyle, setSliderStyle] = useState<string>(styles.slider_opened);

  const [justLoaded, setJustLoaded] = useState<boolean>(false);

  const [checkedFacilities, setCheckedFacilities] = useState<boolean>(false);

  const scrollState = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY !== 0) {
        scrollState.current = currentScrollY;
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setAvailableFacilities([]);
      setSelectedFacilities([]);
      setExpandedTable(undefined);
      if (urlCountry) {
        const getUrlCountryFromData:
          | ICountry
          | undefined = availableCountries.find(
          (country: ICountry) =>
            country.id.toLowerCase() === urlCountry.toLowerCase(),
        );
        setAvailableFacilities(getUrlCountryFromData?.facilities || []);
        if (urlFacilities) {
          const urlFacilitiesArray = urlFacilities.split(',');
          const getUrlFacilitiesFromData = getUrlCountryFromData?.facilities.filter(
            (item) => urlFacilitiesArray.includes(item.id),
          );
          setSelectedFacilities(getUrlFacilitiesFromData || []);
        }
      }
    }
  }, [urlCountry, urlFacilities]);

  useEffect(() => {
    if (selectedFacilities?.length > 0) {
      const facilities = selectedFacilities.map((facility) => facility.id);
      localStorage.setItem(
        `planning${selectedCountry?.id}Facilites`,
        JSON.stringify(facilities),
      );
      push(`/planning/${selectedCountry?.id}/${facilities}`);
    } else {
      push(`/planning/${selectedCountry?.id || ''}`);
    }
  }, [selectedFacilities]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setLoading(true);

        const token = await getToken();
        const { data } = await getAllCountries(token);
        setAvailableCountries(data);
        if (urlCountry) {
          const getUrlCountryFromData: ICountry = data.find(
            (country: ICountry) =>
              country.id.toLowerCase() === urlCountry.toLowerCase(),
          );
          setSelectedCountry(getUrlCountryFromData);
          setAvailableFacilities(getUrlCountryFromData?.facilities || []);
          if (urlFacilities) {
            const urlFacilitiesArray = urlFacilities.split(',');
            const getUrlFacilitiesFromData = getUrlCountryFromData.facilities.filter(
              (item) => urlFacilitiesArray.includes(item.id),
            );
            setSelectedFacilities(getUrlFacilitiesFromData);
          }
        } else {
          const localStorageCountry = localStorage.getItem(PLANNING_COUNTRY);
          if (localStorageCountry) {
            const getLocalStorageCountryFromData: ICountry = data.find(
              (country: ICountry) =>
                country.id.toLowerCase() === localStorageCountry.toLowerCase(),
            );
            setSelectedCountry(getLocalStorageCountryFromData);
            setAvailableFacilities(
              getLocalStorageCountryFromData?.facilities || [],
            );
            push(`/planning/${getLocalStorageCountryFromData?.id}`);

            const localStorageFacilitiesArray = JSON.parse(
              localStorage.getItem(`planning${localStorageCountry}Facilites`) ||
                '',
            );
            if (localStorageFacilitiesArray) {
              const getLocalStorageFacilitiesFromData = getLocalStorageCountryFromData.facilities.filter(
                (item) => localStorageFacilitiesArray.includes(item.id),
              );
              setSelectedFacilities(getLocalStorageFacilitiesFromData);
            }
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        setJustLoaded(true);
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchForecastsAtLoad = async (
      facilities: IPlannedTableFacility[],
    ) => {
      setLoading(true);
      setForecastTables([]);
      try {
        const token = await getToken();
        const { data } = await getAllForecasts(
          token,
          facilities.map((facility) => facility.id),
          startDate.toISOString(),
          endDate.toISOString(),
          checkedCountries,
          checkedFractions,
        );
        setForecastTables(data);
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchForecastsAtLoad(selectedFacilities);
  }, [justLoaded]);

  useEffect(() => {
    const fetchDetailedTable = async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const { data } = await getPlanned(
          token,
          expandedTable?.id,
          startDate.toISOString(),
          endDate.toISOString(),
        );
        setDetailedPlannedTable(data);
        setOriginDetailedPlannedTable(JSON.parse(JSON.stringify(data)));
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (expandedTable?.id) {
      setDetailedPlannedTable(undefined);
      setOriginDetailedPlannedTable(undefined);
      fetchDetailedTable();
    }
  }, [expandedTable, startDate, endDate]);

  useEffect(() => {
    const fetchForecastsFilterValues = async (
      facilities: IPlannedTableFacility[],
    ) => {
      try {
        const token = await getToken();
        const { data } = await getForecastsFilterValues(
          token,
          facilities.map((facility) => facility.id),
        );
        setFilterValues(data);
      } catch (error) {
        // console.log(error);
      }
    };

    if (selectedFacilities?.length > 0) {
      fetchForecastsFilterValues(selectedFacilities);
    } else {
      setFilterValues(null);
    }
  }, [selectedFacilities]);

  const selectCountry = (value: string) => {
    const selected = availableCountries.find(
      (country) => country.name === value,
    );
    if (!selected?.id) {
      localStorage.removeItem(PLANNING_COUNTRY);
    } else {
      localStorage.setItem(PLANNING_COUNTRY, selected?.id);
    }
    push(`/planning/${selected?.id}`);
    setSelectedCountry(selected);
    setAvailableFacilities(selected?.facilities || []);

    const lastCountryFacilitiesIDStorage =
      localStorage.getItem(`planning${selected?.id}Facilites`) || '{}';
    const lastCountryFacilitiesID = JSON.parse(lastCountryFacilitiesIDStorage);
    if (lastCountryFacilitiesID) {
      push(`/planning/${selected?.id}/${lastCountryFacilitiesID}`);
    }
    setExpandedTable(undefined);
  };

  const handlleOnFacilitiesChange = (
    checked: boolean,
    facilitiy: IPlannedTableFacility,
  ) => {
    if (checked) {
      setSelectedFacilities((prev) => [...prev, facilitiy]);
    } else {
      const removeFacility = selectedFacilities.filter(
        (selecetdFacility) => selecetdFacility.id !== facilitiy.id,
      );
      if (facilitiy.id === expandedTable?.id) {
        setExpandedTable(undefined);
      }
      setSelectedFacilities(removeFacility);

      if (removeFacility?.length <= 0) {
        localStorage.removeItem(PLANNING_FACILITIES);
      }
    }
  };

  const checkAllFacilities = () => {
    setCheckedFacilities(false);
    setSelectedFacilities([]);
    setSelectedFacilities(availableFacilities);
  };

  const clearAllFacilities = () => {
    setSelectedFacilities(() => {
      setCheckedFacilities(true);
      return [];
    });
  };

  const fetchComments = async (facilityId?: string) => {
    try {
      const token = await getToken();
      const { data } = await getComments(
        token,
        facilityId,
        startDate.toISOString(),
        endDate.toISOString(),
      );
      setComments(data.comments);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
    }
  };

  const fetchForecasts = async (facilities: IPlannedTableFacility[]) => {
    try {
      const token = await getToken();
      const { data } = await getAllForecasts(
        token,
        facilities.map((facility) => facility.id),
        startDate.toISOString(),
        endDate.toISOString(),
        checkedCountries,
        checkedFractions,
      );
      setForecastTables(data);
    } catch (error) {
      // console.log(error);
    }
  };

  const fetchDetailed = async () => {
    try {
      const token = await getToken();
      const { data } = await getPlanned(
        token,
        expandedTable?.id,
        startDate.toISOString(),
        endDate.toISOString(),
      );
      setDetailedPlannedTable(data);
      setOriginDetailedPlannedTable(JSON.parse(JSON.stringify(data)));
    } catch (error) {
      // console.log(error);
    }
  };

  const mapToUpdateModel = (
    table: IPlanningTableGetModel,
  ): IPlanningTablePutModel[] => {
    const rows = [
      ...table.requiredRows,
      ...table.plannedRows,
      ...table.deliveredRows,
    ];

    const newRows: IPlanningTablePutModel[] = [];
    for (const row of rows) {
      for (const cell of row.cells) {
        newRows.push({
          dimension: row.dimension,
          senderCountryId: String(row.senderCountryId),
          transportationId: String(row.transportationId),
          fractionId: row.fractionId,
          misc: row.misc || '',
          year: cell.year,
          week: cell.week,
          value: cell.value,
        });
      }
    }

    return newRows;
  };

  const getPlanningDiff = (
    originTable: IPlanningTableGetModel,
    newTable: IPlanningTableGetModel,
  ): IPlanningTablePutModel[] => {
    const originRows = mapToUpdateModel(originTable);
    const newRows = mapToUpdateModel(newTable);
    const diff: IPlanningTablePutModel[] = [];

    for (const row of newRows) {
      if (
        !originRows.some(
          (originRow) =>
            originRow.dimension === row.dimension &&
            originRow.senderCountryId === row.senderCountryId &&
            originRow.transportationId === row.transportationId &&
            originRow.fractionId === row.fractionId &&
            originRow.misc === row.misc &&
            originRow.year === row.year &&
            originRow.week === row.week &&
            originRow.value === row.value,
        )
      ) {
        diff.push(row);
      }
    }

    return diff;
  };

  const submitPlan = async (table: IPlanningTableGetModel) => {
    if (originDetailedPlannedTable !== undefined) {
      const payload = getPlanningDiff(originDetailedPlannedTable, table);
      if (payload.length > 0) {
        try {
          setLoading(true);
          const token = await getToken();
          await postPlanned(token, expandedTable?.id, payload);
          toast.success('Planning table updated!');
          setForecastTables([]);
          await fetchForecasts(selectedFacilities);
          setOriginDetailedPlannedTable(undefined);
          setDetailedPlannedTable(undefined);
          await fetchDetailed();
          window.scrollTo(0, scrollState.current);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          toast.error(error?.response?.data?.detail);
        } finally {
          setTableUpdated(false);
          setLoading(false);
        }
      }
    }
  };

  const handleOnExpandButtonClicekd = (facility: IPlannedTableFacility) => {
    if (expandedTable?.id) {
      if (tableUpdated && detailedPlannedTable) {
        submitPlan(detailedPlannedTable);
      }
      setExpandedTable(undefined);
    } else {
      setExpandedTable(facility);
      fetchComments(facility.id);
    }
  };

  const onPostComment = async (comment?: IComment) => {
    try {
      setLoading(true);
      const token = await getToken();
      await postComment(token, expandedTable?.id, comment);
      toast.success('Comment Created!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
    } finally {
      fetchComments(expandedTable?.id);
      setLoading(false);
    }
  };

  const onUpdateComment = async (comment?: IComment) => {
    const payload = {
      message: comment?.message,
      color: comment?.color,
    };
    try {
      setLoading(true);
      const token = await getToken();
      await putComment(token, expandedTable?.id, comment?.id, payload);
      toast.success('Comment updated!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
    } finally {
      fetchComments(expandedTable?.id);
      setLoading(false);
    }
  };

  const onDeleteComment = async (comment?: IComment) => {
    try {
      setLoading(true);
      const token = await getToken();
      await deleteComment(token, expandedTable?.id, comment?.id);
      toast.success('Comment Deleted!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
    } finally {
      fetchComments(expandedTable?.id);
      setLoading(false);
    }
  };

  useEffect(() => {
    const transports: string[] = [];
    if (detailedPlannedTable?.budgetRows) {
      for (const budgetRow of detailedPlannedTable.budgetRows) {
        if (
          !transports.some(
            (t) => t.toString() === budgetRow.transportation.toString(),
          )
        ) {
          transports.push(budgetRow.transportation.toString());
        }
      }
    }
    setCheckedTransports(transports);
    setSendingTransports(transports);
  }, [detailedPlannedTable]);

  const handleSlider = () => {
    if (sliderState) {
      setSliderStyle(styles.slider_closed);
      setSliderState(false);
    } else {
      setSliderStyle(styles.slider_opened);
      setSliderState(true);
    }
  };

  const handleCheckedFractions = (
    event: React.ChangeEvent<HTMLInputElement>,
    fId: string,
    sIndex: number,
  ) => {
    if (checkedFractions.some((cc) => cc.id === fId)) {
      // eslint-disable-next-line array-callback-return
      const newCheckedFractions = checkedFractions.filter((f) => {
        if (f.id !== fId) return f;
      });
      setCheckedFractions(newCheckedFractions);
    } else {
      if (filterValues !== null) {
        setCheckedFractions([
          ...checkedFractions,
          filterValues.sendingFractions[sIndex],
        ]);
      }
    }
  };

  const handleCheckedCountries = (
    event: React.ChangeEvent<HTMLInputElement>,
    fId: string,
    sIndex: number,
  ) => {
    if (checkedCountries.some((cc) => cc.id === fId)) {
      // eslint-disable-next-line array-callback-return
      const newCheckedCountries = checkedCountries.filter((f) => {
        if (f.id !== fId) return f;
      });
      setCheckedCountries(newCheckedCountries);
    } else {
      if (filterValues !== null) {
        setCheckedCountries([
          ...checkedCountries,
          filterValues.sendingCountries[sIndex],
        ]);
      }
    }
  };

  const filterUpdate = async () => {
    setLoading(true);
    try {
      if (checkedFacilities) {
        setCheckedFacilities(false);
        setForecastTables([]);
        localStorage.removeItem(PLANNING_FACILITIES);
      }

      await fetchForecasts(selectedFacilities);
      updateFilters();
      updateDataDimentions();
      window.scrollTo(0, scrollState.current);
    } catch (error) {
      // console.log(error)
    } finally {
      setLoading(false);
    }
  };

  const updateFilters = () => {
    setFilterCountries(checkedCountries);
    setFilterFractions(checkedFractions);
  };

  const updateDataDimentions = () => {
    setShowBudget(showBudgetPending);
    setShowDelivered(showDeliveredPending);
    setShowPlanned(showPlannedPending);
    setShowRequired(showRequiredPending);
  };

  return (
    <PageLayout loading={loading}>
      <PageHeader title='Planning' />
      <div className={sliderStyle}>
        <div className={styles.container}>
          <div className={styles.countryButtonGrid}>
            <div className={styles.dropDownMargin}>
              <DropdownMenu
                selected={selectedCountry?.name || ''}
                disabled={
                  !(availableCountries?.length > 0) || !!expandedTable?.id
                }
                options={
                  availableCountries
                    ? availableCountries?.map((country) => country.name)
                    : []
                }
                updateSelected={(value: string) => selectCountry(value)}
                label='Country'
              />
            </div>
          </div>
          <div className={styles.facilitiesDropDown}>
            <FormControl
              className={styles.facilitiesDropDown}
              component='fieldset'
              disabled={!!expandedTable?.id}
            >
              <div className={classes.facilitylables}>
                <SelectAllControl
                  totalItems={availableFacilities.length || 0}
                  onSelected={(isSelectedAll: boolean) =>
                    isSelectedAll ? checkAllFacilities() : clearAllFacilities()
                  }
                >
                  <FormLabel
                    className={classes.facilitylabel}
                    component='legend'
                  >
                    Facilities
                  </FormLabel>
                </SelectAllControl>
              </div>
              <FormGroup aria-label='position' row>
                {availableFacilities?.map((facility) => (
                  <FormControlLabel
                    key={facility.id}
                    className={classes.facilities}
                    checked={selectedFacilities.some(
                      (fac) => fac.id === facility.id,
                    )}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        onChange={(event) =>
                          handlleOnFacilitiesChange(
                            event.target.checked,
                            facility,
                          )
                        }
                        color='primary'
                      />
                    }
                    label={
                      <span className={classes.facilitieslabel}>
                        {facility.name}
                      </span>
                    }
                    labelPlacement='end'
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          <div className={styles.dataDimentionsDropDown}>
            <div className={styles.dropDownMargin}>
              <FormControl component='fieldset'>
                <SelectAllControl
                  totalItems={4}
                  onSelected={(isSelectedAll: boolean) => {
                    if (isSelectedAll) {
                      setShowBudgetPending(true);
                      setShowRequiredPending(true);
                      setShowPlannedPending(true);
                      setShowDeliveredPending(true);
                    } else {
                      setShowBudgetPending(false);
                      setShowRequiredPending(false);
                      setShowPlannedPending(false);
                      setShowDeliveredPending(false);
                    }
                  }}
                >
                  <FormLabel
                    className={classes.facilitylabel}
                    component='legend'
                  >
                    Data dimensions
                  </FormLabel>
                </SelectAllControl>
                <FormGroup aria-label='filter' row>
                  <FormControlLabel
                    key='budget-filter'
                    checked={showBudgetPending}
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setShowBudgetPending(event.target.checked)
                        }
                        color='primary'
                      />
                    }
                    label='Delivery plan'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    key='required-filter'
                    checked={showRequiredPending}
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setShowRequiredPending(event.target.checked)
                        }
                        color='primary'
                      />
                    }
                    label='Required'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    key={`planned-filter-${Math.random()}`}
                    checked={showPlannedPending}
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setShowPlannedPending(event.target.checked)
                        }
                        color='primary'
                      />
                    }
                    label='Planned'
                    labelPlacement='end'
                  />
                  <FormControlLabel
                    key={`planned-filter-${Math.random()}`}
                    checked={showDeliveredPending}
                    control={
                      <Checkbox
                        onChange={(event) =>
                          setShowDeliveredPending(event.target.checked)
                        }
                        color='primary'
                      />
                    }
                    label='Delivered'
                    labelPlacement='end'
                  />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <div className={styles.dateGrid}>
            <div className={styles.dropDownMargin}>
              <Datepicker
                disabled={!!expandedTable?.id}
                lable='Start date'
                date={startDate}
                setDate={(date: Date | null) =>
                  date && setStartDate(new Date(date))
                }
              />
            </div>
            <div className={styles.dropDownMargin}>
              <Datepicker
                disabled={!!expandedTable?.id}
                lable='End date'
                date={endDate}
                setDate={(date: Date | null) =>
                  date && setEndDate(new Date(date))
                }
                minDate={startDate}
              />
            </div>
          </div>
        </div>
        <div className={styles.sliderButtonContainer}>
          <div className={styles.filterSendingCountry}>
            <FormControl component='fieldset'>
              <SelectAllControl
                totalItems={filterValues?.sendingCountries.length}
                onSelected={(isSelectedAll: boolean) => {
                  if (isSelectedAll && filterValues?.sendingCountries) {
                    setCheckedCountries(filterValues.sendingCountries);
                  } else {
                    setCheckedCountries([]);
                  }
                }}
              >
                <FormLabel className={classes.facilitylabel} component='legend'>
                  Filter by sending country
                </FormLabel>
              </SelectAllControl>
              <FormGroup aria-label='filter' row>
                {filterValues?.sendingCountries?.map((c, sIndex) => (
                  <FormControlLabel
                    key={`${c.name}-filter`}
                    checked={checkedCountries.some((cc) => cc.id === c.id)}
                    control={
                      <Checkbox
                        onChange={(event) => {
                          handleCheckedCountries(event, c.id, sIndex);
                        }}
                        color='primary'
                      />
                    }
                    label={c.id}
                    labelPlacement='end'
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          <div className={styles.filterSendingFraction}>
            <FormControl component='fieldset'>
              <SelectAllControl
                totalItems={filterValues?.sendingFractions.length || 0}
                onSelected={(isSelectedAll: boolean) => {
                  if (isSelectedAll && filterValues?.sendingFractions) {
                    setCheckedFractions(filterValues.sendingFractions);
                  } else {
                    setCheckedFractions([]);
                  }
                }}
              >
                <FormLabel className={classes.facilitylabel} component='legend'>
                  Filter by sending fraction
                </FormLabel>
              </SelectAllControl>

              <FormGroup aria-label='filter' row>
                {filterValues?.sendingFractions?.map((f, sIndex) => (
                  <FormControlLabel
                    key={`${f.name}-filter`}
                    checked={checkedFractions.some((cf) => cf.id === f.id)}
                    control={
                      <Checkbox
                        onChange={(event) => {
                          handleCheckedFractions(event, f.id, sIndex);
                        }}
                        color='primary'
                      />
                    }
                    label={f.name}
                    labelPlacement='end'
                  />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          <div className={styles.fetchDataButton}>
            <Button
              color='primary'
              variant='contained'
              onClick={() => filterUpdate()}
            >
              Apply Filters
            </Button>
          </div>
          <div className={styles.collapseButton}>
            <IconButton
              color='primary'
              aria-label='silder button'
              onClick={() => handleSlider()}
            >
              {sliderState ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            {sliderState ? 'Collapse Filter' : 'Expand Filter'}
          </div>
        </div>
      </div>
      {forecastTables?.length > 0 &&
        forecastTables.map((forecast) => (
          <div key={forecast.facility.id} className={styles.facilityWrapper}>
            <div className={styles.headerGrid}>
              <div className={styles.headerGridTitle}>
                {expandedTable ? <ArrowDropDown /> : <ArrowRight />}
                <Typography classes={classes} variant='h2' component='h1'>
                  Forecast for {forecast.facility.name}
                </Typography>
              </div>
              {expandedTable?.id &&
                expandedTable?.id === forecast?.facility.id &&
                detailedPlannedTable?.facilityId === forecast?.facility.id && (
                  <>
                    <div className={styles.headerGridFilterSendingTransport}>
                      <FormControl component='fieldset'>
                        <SelectAllControl
                          totalItems={sendingTransports.length || 0}
                          onSelected={(isSelectedAll: boolean) => {
                            if (isSelectedAll && sendingTransports) {
                              setCheckedTransports(sendingTransports);
                            } else {
                              setCheckedTransports([]);
                            }
                          }}
                        >
                          <FormLabel
                            className={classes.facilitylabel}
                            component='legend'
                          >
                            Filter by sending transport
                          </FormLabel>
                        </SelectAllControl>

                        <FormGroup aria-label='filter' row>
                          {sendingTransports.map((t) => (
                            <FormControlLabel
                              key={`${t}-filter`}
                              checked={checkedTransports
                                .join()
                                .includes(`${t}`)}
                              control={
                                <Checkbox
                                  onChange={(event) => {
                                    if (event.target.checked) {
                                      setCheckedTransports([
                                        ...checkedTransports,
                                        `${t}`,
                                      ]);
                                    } else {
                                      setCheckedTransports(
                                        checkedTransports.filter(
                                          (id) => id !== `${t}`,
                                        ),
                                      );
                                    }
                                  }}
                                  color='primary'
                                />
                              }
                              label={t}
                              labelPlacement='end'
                            />
                          ))}
                        </FormGroup>
                      </FormControl>
                    </div>
                  </>
                )}
              {(!expandedTable?.id ||
                expandedTable?.id === forecast?.facility?.id) && (
                <div className={styles.headerGridButtons}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() =>
                      handleOnExpandButtonClicekd(forecast.facility)
                    }
                  >
                    {expandedTable ? 'Collapse and save' : 'Expand and edit'}
                  </Button>
                  {expandedTable && (
                    <Button
                      className={styles.marginLeft}
                      color='secondary'
                      variant='contained'
                      onClick={() => setExpandedTable(undefined)}
                    >
                      Collapse
                    </Button>
                  )}
                </div>
              )}
            </div>
            {expandedTable?.id &&
            expandedTable?.id === forecast?.facility.id &&
            detailedPlannedTable?.facilityId === forecast?.facility.id ? (
              <ExpandedPlannedTable
                comments={comments}
                table={detailedPlannedTable}
                onSave={submitPlan}
                setTableUpdated={(updated) => setTableUpdated(updated)}
                onPostComment={(newComment) => onPostComment(newComment)}
                onUpdateComment={(newComment) => onUpdateComment(newComment)}
                onDeleteComment={(newComment) => onDeleteComment(newComment)}
                checkedCountries={filterCountries}
                checkedFractions={filterFractions}
                checkedTransports={checkedTransports}
                showBudget={showBudget}
                showPlanned={showPlanned}
                showRequired={showRequired}
                showDelivered={showDelivered}
              />
            ) : (
              <div className={styles.forecastContent}>
                <CompareTable
                  table={forecast}
                  showBudget={showBudget}
                  showPlanned={showPlanned}
                  showRequired={showRequired}
                  showDelivered={showDelivered}
                />
                <CommentSection
                  startDate={startDate}
                  endDate={endDate}
                  facility={forecast.facility}
                />
              </div>
            )}
          </div>
        ))}
    </PageLayout>
  );
};

export default PlanningPage;
