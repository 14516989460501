import { makeStyles } from '@material-ui/core/styles';
import { alto, blueDianne, white, gallery } from 'styles/jsColors';

const jsStyles = makeStyles({
  table: {
    height: 'max-content',
  },
  tablehead: {
    backgroundColor: alto,
    zIndex: 9,
    position: 'sticky',
    top: 0,
  },
  tablebody: {
    backgroundColor: white,
  },

  cell: {
    padding: '0',
    height: '40px',
    fontSize: '14px',
  },

  headercell: {
    backgroundColor: alto,
    fontSize: '14px',
    border: 'none',
    margin: '0',
    padding: '0 4px',
    minWidth: '80px',
    fontWeight: 'bold',
    zIndex: 9,
  },

  weekheader: {
    backgroundColor: alto,
    fontSize: '14px',
    border: 'none',
    margin: '0',
    padding: '0 4px',
    minWidth: '70px',
    fontWeight: 'bold',
  },

  bold: {
    fontWeight: 'bold',
  },

  stickytotalcell: {
    position: 'sticky',
    right: 0,
  },
  stickycountrycell: {
    position: 'sticky',
    left: '0',
    padding: '0 1rem',
    zIndex: 8,
  },
  stickytransportationcell: {
    position: 'sticky',
    left: '112px',
    padding: '0 1rem',
    zIndex: 8,
  },
  stickyactioncell: {
    position: 'sticky',
    left: '112px',
    padding: '0 1rem',
  },

  stickyfractioncell: {
    position: 'sticky',
    minWidth: '100px',
    left: '239px',
    padding: '0 1rem',
    zIndex: 8,
  },

  actionblock: {
    zIndex: 'revert',
  },

  readonlycell: {
    padding: '0 1rem',
    height: '40px',
    fontSize: '14px',
    backgroundColor: gallery,
    minWidth: '80px',
    border: 'none',
  },
  budgetcell: {
    textAlign: 'center',
    '& :focus': {
      outline: 0,
    },
  },
  savebutton: {
    backgroundColor: blueDianne,
    color: white,
    padding: '1rem 3rem',
  },

  commitbutton: {
    marginLeft: '2rem',
    padding: '1rem 3rem',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },

  closemodalbutton: {
    alignSelf: 'flex-end',
  },
  closeicon: {
    color: white,
    fontSize: '32px',
  },

  tooltip: {
    fontSize: '14px',
  },
});

export default jsStyles;
