import React from 'react';
import Typography from '@material-ui/core/Typography';
import jsStyles from './jsStyles';
import styles from './styles.module.scss';
import ProfileTab from '../ProfileTab/ProfileTab';

type PageHeaderProps = {
  title: string;
};

const PageHeader = ({ title }: PageHeaderProps): JSX.Element => {
  const classes = jsStyles();

  return (
    <div className={styles.pageHeaderCon}>
      <Typography classes={classes} variant='h2' component='h1'>
        {title}
      </Typography>
      <div className={styles.profileTab}>
        <ProfileTab />
      </div>
    </div>
  );
};

export default PageHeader;
