import { makeStyles } from '@material-ui/core/styles';
import { alto, white, black, blueDianne } from 'styles/jsColors';

const jsStyles = makeStyles({
  tablehead: {
    backgroundColor: alto,
  },
  tablebody: {
    backgroundColor: white,
  },

  cell: {
    backgroundColor: white,
    padding: '0',
    minWidth: '75px',
    height: '40px',
    fontSize: '14px',
    userSelect: 'none',
  },

  headercell: {
    backgroundColor: alto,
    minWidth: '95px',
    maxWidth: '95px',
    fontSize: '14px',
    border: 'none',
    margin: '0',
    padding: '1rem 4px',
    fontWeight: 'bold',
  },

  stickytotalcell: {
    position: 'sticky',
    backgroundColor: alto,
    right: 0,
  },

  stickycountrycell: {
    position: 'sticky',
    minWidth: '100px',
    left: '0',
    fontWeight: 'bold',
    zIndex: 10,
    paddingLeft: '1rem',
  },
  stickytransportationcell: {
    position: 'sticky',
    minWidth: '100px',
    left: '120px',
    fontWeight: 'bold',
    zIndex: 10,
    paddingLeft: '1rem',
  },

  stickyfractioncell: {
    position: 'sticky',
    minWidth: '100px',
    left: '240px',
    fontWeight: 'bold',
    zIndex: 10,
    paddingLeft: '1rem',
  },

  typecell: {
    position: 'sticky',
    left: '0',
    backgroundColor: white,
    padding: '0 1rem',
    height: '40px',
    minWidth: '150px',
    fontSize: '14px',
    border: 'none',
  },
  tablefootercell: {
    backgroundColor: alto,
    width: '75px',
    height: '40px',
    color: black,
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '0 1rem',
  },

  savebutton: {
    backgroundColor: blueDianne,
    color: white,
    padding: '1rem 3rem',
  },

  commitbutton: {
    marginLeft: '2rem',
    padding: '1rem 3rem',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },

  closemodalbutton: {
    alignSelf: 'flex-end',
  },

  closeicon: {
    color: white,
    fontSize: '32px',
  },

  tooltip: {
    fontSize: '14px',
  },
});

export default jsStyles;
