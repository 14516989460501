import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { IComment } from 'interfaces/Comment';
import { ColorResult, GithubPicker } from 'react-color';
import styles from './styles.module.scss';
import jsStyles from './jsStyles';

type CommentInputProps = {
  comment?: IComment;
  onMessageChange: (e: React.FocusEvent<HTMLInputElement>) => void;
  onColorChange: (color: string) => void;
};

const CommentInput = ({
  comment,
  onMessageChange,
  onColorChange,
}: CommentInputProps): JSX.Element => {
  const classes = jsStyles();
  const { message = '', lastUpdate = new Date().toISOString(), author } =
    comment || {};
  const [lastUpdateString] = useState(new Date(lastUpdate).toLocaleString());
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  const onColorChangeComplete = (color: ColorResult) => {
    onColorChange(color.hex);
    setDisplayColorPicker(false);
  };

  return (
    <div className={styles.commentInputContainer}>
      <div
        style={{
          backgroundColor: comment?.color,
        }}
        className={styles.cardHeader}
      >
        <p className={styles.cardHeaderText}>{lastUpdateString}</p>
        <Button onClick={() => setDisplayColorPicker((prev) => !prev)}>
          Pick color
        </Button>
      </div>
      {displayColorPicker && (
        <div className={styles.popover}>
          <div className={styles.cover} />
          <GithubPicker
            width='150'
            triangle='top-right'
            onChangeComplete={onColorChangeComplete}
            color={comment?.color}
            colors={['#2ccce4', '#37d67a', '#f47373', '#d9e3f0', '#ba68c8']}
          />
        </div>
      )}
      <TextField
        variant='outlined'
        classes={{ root: classes.inputfield }}
        InputProps={{
          classes: {
            input: classes.input,
          },
        }}
        defaultValue={message || ''}
        multiline
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onBlur={onMessageChange}
        rows={10}
      />
      {author && <p className={styles.authorTitle}>Created by: {author}</p>}
    </div>
  );
};

CommentInput.defaultProps = {
  comment: null,
};

export default CommentInput;
