import { createStyles, makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 200,
      textAlign: 'left',
      fontSize: 10,
      borderWidth: 10,
      padding: '0.4rem 0',
    },

    outlined: {
      padding: '0.8rem 1rem',
    },

    text: {
      fontWeight: 600,
      padding: 0,
      lineHeight: 1,
    },
    dropDownContent: {
      padding: 0,
    },
  }),
);

export default jsStyles;
