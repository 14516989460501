import { makeStyles } from '@material-ui/core/styles';

const jsStyles = makeStyles({
  h2: {
    fontSize: '22px',
    fontWeight: 'bold',
  },
  facilitylabel: {
    textAlign: 'left',
    fontSize: '12px',
  },
  selectlabel: {
    textAlign: 'left',
    fontSize: '12px',
    marginLeft: '10px',
    textDecoration: 'underLine',
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  },
  clearlabel: {
    textAlign: 'left',
    fontSize: '12px',
    marginLeft: '10px',
    textDecoration: 'underLine',
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  },
  checkbox: {
    padding: '1px',
  },
  facilities: {
    paddingLeft: '7px',
    width: '200px',
    justifyContent: 'flex-start',
    textAlign: 'left',
    alignItems: 'center',
  },
  facilitylables: {
    display: 'flex',
  },
  facilitieslabel: {
    fontSize: '.8rem',
  },
  expandicon: {
    backgroundColor: '#fff',
  },
});

export default jsStyles;
