import React from 'react';
import {
  IPlannedForecastTable,
  IPlannedForecastValue,
} from 'interfaces/PlannedTable';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import cn from 'classnames';
import jsStyles from './jsStyles';
import styles from './styles.module.scss';

type ForecastTableProps = {
  table: IPlannedForecastTable;
  showBudget?: boolean;
  showRequired?: boolean;
  showPlanned?: boolean;
  showDelivered?: boolean;
};

const BUDGET_DEVIATION = 'budgetDeviation';
const BUDGET_FORECAST = 'budgetForecast';
const PLANNED_DEVIATION = 'plannedDeviation';
const PLANNED_FORECAST = 'plannedForecast';
const REQURIED_DEVIATION = 'requriedDeviation';
const REQUIRED_FORECAST = 'requiredForecast';
const PLANNED_REQUIRED_DEVIATION = 'plannedRequiredDeviation';
const DELIVERED = 'delivered';

type Diementions =
  | 'budgetDeviation'
  | 'budgetForecast'
  | 'plannedDeviation'
  | 'plannedForecast'
  | 'requiredForecast'
  | 'requriedDeviation'
  | 'plannedRequiredDeviation'
  | 'delivered';

const CompareTable = ({
  table,
  showBudget,
  showPlanned,
  showRequired,
  showDelivered,
}: ForecastTableProps): JSX.Element => {
  const { forecasts } = table;
  const classes = jsStyles();

  const totalRow = (row: IPlannedForecastValue[], type: Diementions) => {
    return row?.reduce((sum: number, item) => {
      return sum + item[type];
    }, 0);
  };

  return (
    <div className={styles.compareTableWrapper}>
      <Table aria-label='forecast table'>
        <TableHead className={classes.tablehead}>
          <TableRow>
            <TableCell
              align='left'
              colSpan={2}
              className={classes.stickyheadercell}
            />
            {forecasts?.length > 0 &&
              forecasts.map((row) => (
                <TableCell
                  className={(classes.cell, classes.headercell)}
                  key={`header-week ${row.week}`}
                  align='center'
                >{`Week ${Number(row.week)}`}</TableCell>
              ))}
            <TableCell
              className={cn(
                classes.cell,
                classes.headercell,
                classes.stickytotalcell,
              )}
              key='Total'
              align='center'
            >
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tablebody}>
          {showBudget && (
            <TableRow key='budgetForecast'>
              <TableCell align='left' className={classes.typecell} colSpan={2}>
                Delivery plan
              </TableCell>
              {forecasts?.length > 0 &&
                forecasts.map((row) => (
                  <TableCell
                    className={classes.cell}
                    key={`Delivery plan - ${row.week}`}
                    align='center'
                  >
                    <NumberFormat
                      value={row.budgetForecast}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                ))}
              <TableCell
                className={cn(classes.cell, classes.stickytotalcell)}
                key='Total'
                align='center'
              >
                {totalRow(forecasts, BUDGET_FORECAST)}
              </TableCell>
            </TableRow>
          )}
          {showRequired && (
            <TableRow key='requiredForecast'>
              <TableCell align='left' className={classes.typecell} colSpan={2}>
                Required
              </TableCell>
              {forecasts?.length > 0 &&
                forecasts.map((row) => (
                  <TableCell
                    className={classes.cell}
                    key={`Required - ${row.week}`}
                    align='center'
                  >
                    <NumberFormat
                      value={row.requiredForecast}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                ))}
              <TableCell
                className={cn(classes.cell, classes.stickytotalcell)}
                key='Total'
                align='center'
              >
                {totalRow(forecasts, REQUIRED_FORECAST)}
              </TableCell>
            </TableRow>
          )}
          {showPlanned && (
            <TableRow key='plannedForecast'>
              <TableCell align='left' className={classes.typecell} colSpan={2}>
                Planned
              </TableCell>
              {forecasts?.length > 0 &&
                forecasts.map((row) => (
                  <TableCell
                    className={classes.cell}
                    key={`Planned - ${row.week}`}
                    align='center'
                  >
                    <NumberFormat
                      value={row.plannedForecast}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                ))}
              <TableCell
                className={cn(classes.cell, classes.stickytotalcell)}
                key='Total'
                align='center'
              >
                {totalRow(forecasts, PLANNED_FORECAST)}
              </TableCell>
            </TableRow>
          )}
          {showDelivered && (
            <TableRow key='delivered'>
              <TableCell align='left' className={classes.typecell} colSpan={2}>
                Delivered
              </TableCell>
              {forecasts?.length > 0 &&
                forecasts.map((row) => (
                  <TableCell
                    className={classes.cell}
                    key={`Delivered - ${row.week}`}
                    align='center'
                  >
                    <NumberFormat
                      value={row.delivered}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                ))}
              <TableCell
                className={cn(classes.cell, classes.stickytotalcell)}
                key='Total'
                align='center'
              >
                {totalRow(forecasts, DELIVERED)}
              </TableCell>
            </TableRow>
          )}
          {showBudget && showDelivered && (
            <TableRow key='budgetDeviation'>
              <TableCell align='left' className={classes.typecell} colSpan={2}>
                Deviation: Delivered - Delivery plan
              </TableCell>
              {forecasts?.length > 0 &&
                forecasts.map((row) => (
                  <TableCell
                    className={cn(
                      classes.cell,
                      row.budgetDeviation < 0
                        ? classes.negativcell
                        : classes.positivecell,
                    )}
                    key={`Delivery plan Deviation - ${row.week}`}
                    align='center'
                  >
                    <NumberFormat
                      value={row.budgetDeviation}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                ))}
              <TableCell
                className={cn(classes.cell, classes.stickytotalcell)}
                key='Total'
                align='center'
              >
                {totalRow(forecasts, BUDGET_DEVIATION)}
              </TableCell>
            </TableRow>
          )}
          {showRequired && showDelivered && (
            <TableRow key='requriedDeviation'>
              <TableCell align='left' className={classes.typecell} colSpan={2}>
                Deviation: Delivered - Required
              </TableCell>
              {forecasts?.length > 0 &&
                forecasts.map((row) => (
                  <TableCell
                    className={cn(
                      classes.cell,
                      row.requriedDeviation < 0
                        ? classes.negativcell
                        : classes.positivecell,
                    )}
                    key={`RequriedDeviation - ${row.week}`}
                    align='center'
                  >
                    <NumberFormat
                      value={row.requriedDeviation}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                ))}
              <TableCell
                className={cn(classes.cell, classes.stickytotalcell)}
                key='Total'
                align='center'
              >
                {totalRow(forecasts, REQURIED_DEVIATION)}
              </TableCell>
            </TableRow>
          )}
          {showPlanned && showDelivered && (
            <TableRow key='plannedDeviation'>
              <TableCell align='left' className={classes.typecell} colSpan={2}>
                Deviation: Delivered - Planned
              </TableCell>
              {forecasts?.length > 0 &&
                forecasts.map((row) => (
                  <TableCell
                    className={cn(
                      classes.cell,
                      row.plannedDeviation < 0
                        ? classes.negativcell
                        : classes.positivecell,
                    )}
                    key={`PlannedDeviation - ${row.week}`}
                    align='center'
                  >
                    <NumberFormat
                      value={row.plannedDeviation}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                ))}
              <TableCell
                className={cn(classes.cell, classes.stickytotalcell)}
                key='Total'
                align='center'
              >
                {totalRow(forecasts, PLANNED_DEVIATION)}
              </TableCell>
            </TableRow>
          )}
          {showPlanned && showRequired && (
            <TableRow key='plannedRequiredDeviation'>
              <TableCell align='left' className={classes.typecell} colSpan={2}>
                Deviation: Planned - Required
              </TableCell>
              {forecasts?.length > 0 &&
                forecasts.map((row) => (
                  <TableCell
                    className={cn(
                      classes.cell,
                      row.plannedRequiredDeviation < 0
                        ? classes.negativcell
                        : classes.positivecell,
                    )}
                    key={`plannedRequiredDeviation - ${row.week}`}
                    align='center'
                  >
                    <NumberFormat
                      value={row.plannedRequiredDeviation}
                      readOnly
                      thousandSeparator={' '}
                      type='text'
                      displayType='text'
                      isNumericString
                    />
                  </TableCell>
                ))}
              <TableCell
                className={cn(classes.cell, classes.stickytotalcell)}
                key='Total'
                align='center'
              >
                {totalRow(forecasts, PLANNED_REQUIRED_DEVIATION)}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

CompareTable.defaultProps = {
  showBudget: true,
  showRequired: true,
  showPlanned: true,
  showDelivered: true,
};

export default CompareTable;
