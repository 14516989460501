import React from 'react';
import ReactLoading from 'react-loading';
import styles from './styles.module.scss';

type AuthLoaderProps = {
  loading: boolean;
};

const AuthLoader = ({ loading }: AuthLoaderProps): JSX.Element => (
  <>
    {loading && (
      <div className={styles.loadingWrapper}>
        <ReactLoading type='spin' height={150} width={150} />
      </div>
    )}
  </>
);

AuthLoader.defaultProps = {
  loading: false,
};

export default AuthLoader;
