/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BudgetTable, PageHeader, PageLayout, DropdownMenu } from 'components';
import { useAuth } from '@innit/msal-browser-react';
import {
  deleteComment,
  getAllCountries,
  getBudget,
  getBudgetComments,
  postBudget,
  postComment,
  putComment,
} from 'services/Api';
import {
  IBudget,
  IBudgetTablePayload,
  ITransportation,
} from 'interfaces/BudgetTable';
import { toast } from 'react-toastify';
import LockIcon from '@material-ui/icons/Lock';
import { IComment } from 'interfaces/Comment';
import { ICountry, IFacility } from 'interfaces/Country';
import { Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import {
  BUDGET_COUNTRY,
  BUDGET_FACILITY,
  BUDGET_YEAR,
} from 'utils/LocalStorageKeys';
import jsStyles from './jsStyles';
import styles from './styles.module.scss';

interface ParamTypes {
  urlCountry: string;
  urlFacilityId: string;
  urlYear: string;
}

const BudgetPage = (): JSX.Element => {
  const { push } = useHistory();
  const { urlCountry, urlFacilityId, urlYear } = useParams<ParamTypes>();
  const { getToken } = useAuth();

  const classes = jsStyles();

  const [loading, setLoading] = useState<boolean>(false);

  const [availableCountries, setAvailableCountries] = useState<ICountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>();

  const [availableFacilities, setAvailableFacilities] = useState<IFacility[]>(
    [],
  );
  const [selectedFacility, setSelectedFacility] = useState<IFacility>();

  const [availableYears, setAvailableYears] = useState<string[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>();

  const [budgetTable, setBudgetTable] = useState<IBudget>();
  const [comments, setComments] = useState<IComment[]>([]);

  useEffect(() => {
    if (selectedCountry) {
      if (urlCountry) {
        setBudgetTable(undefined);
        setSelectedCountry(undefined);
        setSelectedFacility(undefined);
        setSelectedYear(undefined);
        const getUrlCountryFromData:
          | ICountry
          | undefined = availableCountries.find(
          (country: ICountry) =>
            country.id.toLowerCase() === urlCountry.toLowerCase(),
        );
        setSelectedCountry(getUrlCountryFromData);
        setAvailableFacilities(getUrlCountryFromData?.facilities || []);
        if (getUrlCountryFromData && urlFacilityId) {
          const getUrlFacilityFromData:
            | IFacility
            | undefined = getUrlCountryFromData?.facilities.find(
            (facility: IFacility) =>
              facility.id.toLowerCase() === urlFacilityId.toLowerCase(),
          );
          setSelectedFacility(getUrlFacilityFromData);
          setAvailableYears(getUrlFacilityFromData?.years || []);
          if (getUrlFacilityFromData && urlYear) {
            const getUrlYearFromData:
              | string
              | undefined = getUrlFacilityFromData.years.find(
              (year) => String(year) === urlYear.toLowerCase(),
            );
            setSelectedYear(getUrlYearFromData);
          }
        }
      }
    }
  }, [urlCountry, urlFacilityId, urlYear]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const { data } = await getAllCountries(token);
        setAvailableCountries(data);
        if (urlCountry) {
          const getUrlCountryFromData: ICountry = data.find(
            (country: ICountry) =>
              country.id.toLowerCase() === urlCountry.toLowerCase(),
          );
          setSelectedCountry(getUrlCountryFromData);
          setAvailableFacilities(getUrlCountryFromData.facilities);
          if (getUrlCountryFromData && urlFacilityId) {
            const getUrlFacilityFromData:
              | IFacility
              | undefined = getUrlCountryFromData?.facilities.find(
              (facility: IFacility) =>
                facility.id.toLowerCase() === urlFacilityId.toLowerCase(),
            );
            setSelectedFacility(getUrlFacilityFromData);
            setAvailableYears(getUrlFacilityFromData?.years || []);
            if (getUrlFacilityFromData && urlYear) {
              const getUrlYearFromData:
                | string
                | undefined = getUrlFacilityFromData.years.find(
                (year) => String(year) === urlYear.toLowerCase(),
              );
              setSelectedYear(getUrlYearFromData);
            }
          }
        } else {
          const localStorageCountry = localStorage.getItem(BUDGET_COUNTRY);
          if (localStorageCountry) {
            const getLocalStorageCountryFromData: ICountry = data.find(
              (country: ICountry) =>
                country.id.toLowerCase() === localStorageCountry.toLowerCase(),
            );
            setSelectedCountry(getLocalStorageCountryFromData);
            setAvailableFacilities(getLocalStorageCountryFromData.facilities);
            push(`/budget/${localStorageCountry}`);

            const localStorageFacility = localStorage.getItem(BUDGET_FACILITY);
            if (localStorageFacility) {
              const getLocalStorageFacilityFromData:
                | IFacility
                | undefined = getLocalStorageCountryFromData?.facilities.find(
                (facility: IFacility) =>
                  facility.id.toLowerCase() ===
                  localStorageFacility.toLowerCase(),
              );
              setSelectedFacility(getLocalStorageFacilityFromData);
              setAvailableYears(getLocalStorageFacilityFromData?.years || []);
              push(
                `/budget/${localStorageCountry}/${getLocalStorageFacilityFromData?.id}`,
              );

              const localStorageYear = localStorage.getItem(BUDGET_YEAR);
              if (localStorageYear) {
                const getLocalStorageYearFromData:
                  | string
                  | undefined = getLocalStorageFacilityFromData?.years.find(
                  (year) => String(year) === localStorageYear.toLowerCase(),
                );
                setSelectedYear(getLocalStorageYearFromData);
                push(
                  `/budget/${localStorageCountry}/${getLocalStorageFacilityFromData?.id}/${getLocalStorageYearFromData}`,
                );
              }
            }
          }
        }
      } catch (error) {
        // console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    const fetchBudget = async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const { data } = await getBudget(
          token,
          selectedFacility?.id,
          selectedYear,
        );
        setBudgetTable(data);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(error?.response?.data?.detail);
      } finally {
        setLoading(false);
      }
    };
    const fetchBudgetComments = async () => {
      try {
        if (!loading) {
          setLoading(true);
        }
        const token = await getToken();
        const { data } = await getBudgetComments(
          token,
          selectedFacility?.id,
          selectedYear,
        );
        setComments(data.comments);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.error(error?.response?.data?.detail);
      } finally {
        if (loading) {
          setLoading(false);
        }
      }
    };
    if (selectedCountry && selectedFacility && selectedYear) {
      setBudgetTable(undefined);
      fetchBudget();
      fetchBudgetComments();
    } else {
      setBudgetTable(undefined);
    }
  }, [selectedCountry, selectedFacility, selectedYear]);

  const fetchBudgetComments = async () => {
    try {
      setLoading(true);

      const token = await getToken();
      const { data } = await getBudgetComments(
        token,
        selectedFacility?.id,
        selectedYear,
      );
      setComments(data.comments);
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const selectCountry = (value: string) => {
    const selected = availableCountries.find(
      (country) => country.name === value,
    );
    if (!selected?.id) {
      localStorage.removeItem(BUDGET_COUNTRY);
    } else {
      localStorage.setItem(BUDGET_COUNTRY, selected?.id);
    }
    setSelectedCountry(selected);
    push(`/budget/${selected?.id}`);
    setAvailableFacilities(selected?.facilities || []);
    setSelectedYear(undefined);
    setBudgetTable(undefined);
    setComments([]);
  };

  const selectFacility = (value: string) => {
    const selected = availableFacilities.find(
      (facility) => facility.name === value,
    );
    if (!selected?.id) {
      localStorage.removeItem(BUDGET_FACILITY);
    } else {
      localStorage.setItem(BUDGET_FACILITY, selected?.id);
    }
    setSelectedFacility(selected);
    push(`/budget/${selectedCountry?.id}/${selected?.id}`);
    setAvailableYears(selected?.years || []);
    setSelectedYear(undefined);
    setBudgetTable(undefined);
    setComments([]);
  };

  const selectYear = (value: string) => {
    if (!value) {
      localStorage.removeItem(BUDGET_YEAR);
    } else {
      localStorage.setItem(BUDGET_YEAR, value);
    }
    setSelectedYear(value);
    push(`/budget/${selectedCountry?.id}/${selectedFacility?.id}/${value}`);
  };

  const submitBudget = async (table: ITransportation[], lockBudget = false) => {
    const payload: IBudgetTablePayload[] = [];
    table.map((transportaion) =>
      transportaion.values.map((value) => {
        return payload.push({
          year: value.year,
          week: value.week,
          senderCountryId: transportaion.senderCountry.id,
          transportationId: String(transportaion.id),
          fractionId: transportaion.fraction.id,
          dimension: value.dimension,
          value: value.value,
        });
      }),
    );
    try {
      setLoading(true);
      const token = await getToken();
      await postBudget(
        token,
        selectedFacility?.id,
        selectedYear,
        payload,
        lockBudget,
      );
      toast.success('Delivery plan table updated!');
      const { data } = await getBudget(
        token,
        selectedFacility?.id,
        selectedYear,
      );
      setBudgetTable(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false);
    }
  };

  const onPostComment = async (comment?: IComment) => {
    try {
      setLoading(true);
      const token = await getToken();
      await postComment(token, selectedFacility?.id, comment);
      toast.success('Comment Created!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false);

      fetchBudgetComments();
    }
  };

  const onUpdateComment = async (comment?: IComment) => {
    const payload = {
      message: comment?.message,
      color: comment?.color,
    };
    try {
      setLoading(true);
      const token = await getToken();
      await putComment(token, selectedFacility?.id, comment?.id, payload);
      toast.success('Comment updated!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false);

      fetchBudgetComments();
    }
  };

  const onDeleteComment = async (comment?: IComment) => {
    try {
      setLoading(true);
      const token = await getToken();
      await deleteComment(token, selectedFacility?.id, comment?.id);
      toast.success('Comment Deleted!');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast.error(error?.response?.data?.detail);
    } finally {
      setLoading(false);
      fetchBudgetComments();
    }
  };

  return (
    <PageLayout loading={loading}>
      <PageHeader title='Delivery plan' />
      <div className={styles.dropDownsWrapper}>
        <div className={styles.dropDownMargin}>
          <DropdownMenu
            selected={selectedCountry?.name || ''}
            disabled={!(availableCountries?.length > 0)}
            options={
              availableCountries
                ? availableCountries?.map((country) => country.name)
                : []
            }
            updateSelected={(value: string) => selectCountry(value)}
            label='Country'
          />
        </div>
        <div className={styles.dropDownMargin}>
          <DropdownMenu
            selected={selectedFacility?.name || ''}
            disabled={!(availableFacilities?.length > 0)}
            options={
              availableFacilities
                ? availableFacilities?.map((facility) => facility.name)
                : []
            }
            updateSelected={(value: string) => selectFacility(value)}
            label='Facility'
          />
        </div>
        <div className={styles.dropDownMargin}>
          <DropdownMenu
            disabled={!(availableYears?.length > 0)}
            selected={selectedYear || ''}
            options={availableYears}
            updateSelected={(value: string) => selectYear(value)}
            label='Year'
          />
        </div>
      </div>

      <div className={styles.pageWrapper}>
        <div className={styles.infoContainer}>
          {budgetTable?.transportation && (
            <div className={styles.tableContainer}>
              <div className={styles.tableTitleWrapper}>
                <Typography
                  classes={classes}
                  variant='h2'
                  component='h1'
                >{`Delivery plan for ${budgetTable?.name}`}</Typography>
                {budgetTable.locked && <LockIcon />}
              </div>
              <BudgetTable
                table={budgetTable}
                comments={comments}
                onSave={submitBudget}
                onPostComment={(newComment) => onPostComment(newComment)}
                onUpdateComment={(newComment) => onUpdateComment(newComment)}
                onDeleteComment={(newComment) => onDeleteComment(newComment)}
              />
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default BudgetPage;
