import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormLabel } from '@material-ui/core';

type SelectAllProps = {
  children?: unknown;
  totalItems?: number;
  onSelected(isSelectedAll: boolean): void;
};

export function SelectAllControl({
  children,
  totalItems = 0,
  onSelected,
}: SelectAllProps) {
  const classes = jsStyles();

  return (
    <div className={classes.facilitylables}>
      {children}
      {totalItems > 1 && (
        <>
          <FormLabel
            onClick={() => onSelected(true)}
            className={classes.selectlabel}
            component='legend'
          >
            Select all
          </FormLabel>
          <FormLabel
            onClick={() => onSelected(false)}
            className={classes.clearlabel}
            component='legend'
          >
            Clear all
          </FormLabel>
        </>
      )}
    </div>
  );
}

const jsStyles = makeStyles({
  selectlabel: {
    textAlign: 'left',
    fontSize: '12px',
    marginLeft: '10px',
    textDecoration: 'underLine',
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  },
  clearlabel: {
    textAlign: 'left',
    fontSize: '12px',
    marginLeft: '10px',
    textDecoration: 'underLine',
    '&:hover': {
      cursor: 'pointer',
      color: 'black',
    },
  },
  facilitylables: {
    display: 'flex',
  },
});
