/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { appSettings } from 'config/appSettings';

const devUrl = `${appSettings.api.host}/v1`;

const API = axios.create({
  baseURL: devUrl,
  responseType: 'json',
});

const getMe = (token) => {
  return API({
    method: 'GET',
    url: '/me',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getAllCountries = (token) => {
  return API({
    method: 'GET',
    url: '/countries',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getBudget = (token, facilityId, year) => {
  return API({
    method: 'GET',
    url: `/facilities/${facilityId}/budgetnumbers?year=${year}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postBudget = (token, facilityId, year, data, lockBudget = false) => {
  return API({
    method: 'PUT',
    url: `/facilities/${facilityId}/budgetnumbers?year=${year}&lockBudget=${lockBudget}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

const getBudgetComments = (token, facilityId, year) => {
  return API({
    method: 'GET',
    url: `/facilities/${facilityId}/budgetcomments?year=${year}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getComments = (token, facilityId, startDate, endDate) => {
  return API({
    method: 'GET',
    url: `/facilities/${facilityId}/comments?from=${startDate}&to=${endDate}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postComment = (token, facilityId, data) => {
  return API({
    method: 'POST',
    url: `/facilities/${facilityId}/comments`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

const putComment = (token, facilityId, commentId, data) => {
  return API({
    method: 'PUT',
    url: `/facilities/${facilityId}/comments/${commentId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

const deleteComment = (token, facilityId, commentId) => {
  return API({
    method: 'DELETE',
    url: `/facilities/${facilityId}/comments/${commentId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getPlanned = (token, facilityId, startDate, endDate) => {
  return API({
    method: 'GET',
    url: `/facilities/${facilityId}/planningnumbers?from=${startDate}&to=${endDate}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const postPlanned = (token, facilityId, data) => {
  return API({
    method: 'PUT',
    url: `/facilities/${facilityId}/planningnumbers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

const getForecast = (token, facilityId, startDate, endDate) => {
  return API({
    method: 'GET',
    url: `/facilities/${facilityId}/forecast?from=${startDate}&to=${endDate}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getForecastsFilterValues = (token, facilityIds) => {
  let ids = '';

  facilityIds.forEach((id) => {
    ids += `facilityIds=${id}&`;
  });
  return API({
    method: 'GET',
    url: `/forecasts/filtervalues?${ids}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getAllForecasts = (
  token,
  facilityIds,
  startDate,
  endDate,
  sendingCountries,
  sendingFractions,
) => {
  let ids = '';

  facilityIds.forEach((id) => {
    ids += `facilityIds=${id}&`;
  });

  let countries = '';

  sendingCountries.forEach((c) => {
    countries += `senderCountries=${c.id}&`;
  });

  let fractions = '';

  sendingFractions.forEach((f) => {
    fractions += `fractions=${f.id}&`;
  });

  return API({
    method: 'GET',
    url: `/forecasts?${ids}from=${startDate}&to=${endDate}&${countries}${fractions}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export {
  getMe,
  getAllCountries,
  getBudget,
  postBudget,
  getBudgetComments,
  getComments,
  postComment,
  putComment,
  deleteComment,
  getPlanned,
  postPlanned,
  getForecast,
  getAllForecasts,
  getForecastsFilterValues,
};
