import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useLocation,
  withRouter,
  Redirect,
} from 'react-router-dom';
import {
  BudgetPage,
  DrilldownPage,
  PlanningPage,
  Page404,
  ErrorPage,
} from 'pages';
import { getMe } from 'services/Api';
import { useAuth } from '@innit/msal-browser-react';
import { AuthLoader } from 'components';

const Routes = () => {
  const { loading, authenticated, login, getToken } = useAuth();
  const [authorized, setAuthorized] = useState<boolean | null>(null);
  const [authError, setAuthError] = useState({
    code: 500,
    reason: 'Unknown Error',
  });
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!loading && !authenticated) login();

    // eslint-disable-next-line
  }, [loading, authenticated])

  useEffect(() => {
    if (!loading && authenticated && authorized === null) {
      getToken().then((token) => {
        getMe(token)
          .then((data) => {
            if (data.status !== 200) {
              setAuthorized(false);
              setAuthError({ code: data.status, reason: data.statusText });
            } else {
              setAuthorized(true);
            }
          })
          .catch((error) => {
            setAuthorized(false);
            setAuthError({
              code: error.response.status,
              reason: error.response.statusText,
            });
          });
      });
    }

    // eslint-disable-next-line
  }, [loading, authenticated])

  return authorized === true ? (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/budget' />
      </Route>
      <Route
        path='/budget/:urlCountry?/:urlFacilityId?/:urlYear?'
        component={BudgetPage}
      />
      <Route
        exact
        path='/planning/:urlCountry?/:urlFacilities?'
        component={PlanningPage}
      />
      <Route
        exact
        path='/drilldown/:urlCountry?/:urlFacilities?'
        component={DrilldownPage}
      />
      <Route path='*' component={Page404} />
    </Switch>
  ) : authorized === false ? (
    <ErrorPage code={authError.code} reason={authError.reason} />
  ) : (
    <AuthLoader loading={true} />
  );
};

export default withRouter(Routes);
