import React from 'react';
import DayPicker from 'react-day-picker';
import {
  Popover,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/en-gb';

type DatepickerProps = {
  date: Date;
  lable: string;
  setDate: (value: Date | null) => void;
  minDate?: Date;
  disabled?: boolean;
};

const Datepicker = ({
  date,
  setDate,
  lable,
  disabled = false,
  minDate = new Date(2000, 0, 1),
}: DatepickerProps): JSX.Element => {
  const handleDateChange = (newDate: Date | null) => {
    setDate(newDate);
  };

  return (
    <PopupState variant='popover' popupId='demo-popup-popover'>
      {(popupState) => (
        <div>
          <TextField
            id='standard-read-only-input'
            label={lable}
            value={moment(date).format('DD.MM.yyyy')}
            disabled={disabled}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton disabled={disabled} {...bindTrigger(popupState)}>
                    <EventIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant='standard'
          />
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <DayPicker
              localeUtils={MomentLocaleUtils}
              locale='en-gb'
              showWeekNumbers
              selectedDays={date}
              onDayClick={handleDateChange}
              disabledDays={[
                {
                  before: minDate,
                },
              ]}
            />
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

export default Datepicker;
